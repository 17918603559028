import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import { FileUploader } from "react-drag-drop-files";
import { FetchContext } from "../../context/FetchContext";

const FileUploadQuestion = ({
  data,
  questionNumber,
  redirectPrevURL,
  redirectURL,
  topText,
  isBack,
  result_json_data,
  setQuiz_logic_jump_data,
  quiz,
  result,
}) => {
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const [value, setValue] = useState("");
  const [File, setFile] = useState([{ file: "" }]);
  const [error, seterror] = useState({ err: "" });
  // const [fileObj, setfileObj] = useState({});
  const [fileArray, setfileArray] = useState({});
  const [localFileArray, setLocalFileArray] = useState([]);

  const [singlepreview, setsinglepreview] = useState();
  const [localSinglePreview, setLocalSinglePreview] = useState();
  const [imgName, setimgName] = useState([]);
  const navigate = useNavigate();

  function localSorageData() {
    var valueArr = JSON.parse(localStorage.getItem("quizData"));
    if (valueArr && valueArr[questionNumber]?.answerId) {
      if (data.multiselect_answer) {
        setLocalFileArray(valueArr[questionNumber]?.answerId);
      } else {
        setLocalSinglePreview(valueArr[questionNumber]?.answerId);
      }
      setimgName(valueArr[questionNumber]?.answerId);
    }
  }

  useEffect(() => {
    setQuiz_logic_jump_data((prevData) => {
      const newData = [...prevData];
      newData[questionNumber] = data.leads_to_id;
      return newData;
    });
  }, [data]);
  const handleFileUploadChange = async (file) => {
    setFile([{ file: file }]);
    // setfileObj(file);
    seterror({ err: "" });
    setLocalFileArray("");
    setLocalSinglePreview("");

    const newFileArray = {};
    // multi file on change
    if (file && file.length <= 3) {
      setfileArray({});
      for (const [key, value] of Object.entries(file)) {
        if (data.question_layout_style === "image") {
          newFileArray[key] = URL.createObjectURL(value);
        } else {
          newFileArray[key] = value.name;
        }
        setfileArray(newFileArray);
      }
    } else {
      setfileArray({});
    }
    //single file onchange
    if (data.multiselect_answer === false) {
      if (data.question_layout_style === "image") {
        setsinglepreview(URL.createObjectURL(file));
      } else {
        setsinglepreview(file.name);
      }
    }
    if (error.err == "" && (file?.length <= 3 || file)) {
      handleFileUpload(file);
    }
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    // multi file upload
    if (data.multiselect_answer === true) {
      for (let i = 0; i < file.length; i++) {
        formData.append("files", file[i]);
      }
    }
    // single file upload
    if (data.multiselect_answer === false) {
      formData.append("files", file);
    }
    try {
      const response = await authAxios.post(
        `${process.env.REACT_APP_API_URL}/submission/upload/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setimgName(response.data.data);
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const handleRedirect = (dir) => {
    if (localStorage.getItem("quizData")) {
      var valueArr = JSON.parse(localStorage.getItem("quizData"));
    } else {
      var valueArr = {};
    }

    valueArr[`${questionNumber}`] = {
      questionId: data._id,
      answerId: imgName,
      answerIds: null,
      resultWeightIds: null,
      result_logic: result_json_data.result_logic,
      product_weight: null,
      product_point: null,
      quizId: data.quiz_id,
    };
    localStorage.setItem("quizData", JSON.stringify(valueArr));
    setValue("");
    setfileArray({});
    setLocalFileArray([]);
    setimgName("");

    if (dir) {
      if (data.multiselect_answer === true) {
        let backActionArr = localStorage.getItem("back_action")
          ? JSON.parse(localStorage.getItem("back_action"))
          : [];

        if (data.leads_to_id) {
          backActionArr.push(data.leads_to_id);
        } else {
          if (result.length - 1 > questionNumber) {
            backActionArr.push(result[questionNumber + 1]?._id);
          }
        }

        localStorage.setItem("back_action", JSON.stringify(backActionArr));
      } else {
        let backActionArr = localStorage.getItem("back_action")
          ? JSON.parse(localStorage.getItem("back_action"))
          : [];

        if (result.length - 1 > questionNumber) {
          backActionArr.push(result[questionNumber + 1]?._id);
        }

        localStorage.setItem("back_action", JSON.stringify(backActionArr));
      }
      navigate(redirectURL);
    } else {
      if (quiz.logic_jump === true) {
        const back_action_arr = JSON.parse(localStorage.getItem("back_action"));
        const question_id = back_action_arr.pop();
        const secondLastId =
          back_action_arr.length !== 0
            ? back_action_arr[back_action_arr.length - 1]
            : result[0]?._id;
        localStorage.setItem("back_action", JSON.stringify(back_action_arr));
        const prevQuestionIndex = result.findIndex(
          (question) => question._id === secondLastId
        );
        const prevQuestionObject = result.find(
          (question) => question._id === secondLastId
        );

        navigate(`/question/${prevQuestionObject?._id}/${prevQuestionIndex}`);
      } else {
        navigate(redirectPrevURL);
      }
    }
  };
  function handleSkipRedirect(dir) {
    if (localStorage.getItem("quizData")) {
      var valueArr = JSON.parse(localStorage.getItem("quizData"));
    } else {
      var valueArr = {};
    }
    valueArr[`${questionNumber}`] = {
      questionId: data._id,
      answerId: null,
      answerIds: null,
      resultWeightIds: null,
      result_logic: result_json_data.result_logic,
      product_weight: null,
      product_point: null,
      quizId: data.quiz_id,
    };
    localStorage.setItem("quizData", JSON.stringify(valueArr));
    setValue("");
    if (dir) {
      let backActionArr = localStorage.getItem("back_action")
        ? JSON.parse(localStorage.getItem("back_action"))
        : [];

      if (data.leads_to_id) {
        backActionArr.push(data.leads_to_id);
      } else {
        if (result.length - 1 > questionNumber) {
          backActionArr.push(result[questionNumber + 1]?._id);
        }
      }
      localStorage.setItem("back_action", JSON.stringify(backActionArr));
      const skipQuestionIndex = result.findIndex(
        (question) => question._id === data.leads_to_id
      );
      const skipQuestionObject = result.find(
        (question) => question._id === data.leads_to_id
      );
      if (quiz.logic_jump === true) {
        if (data.leads_to_id === "result_page") {
          navigate("/subscribe");
        } else if (!data.leads_to_id) {
          if (result.length - 1 > questionNumber) {
            navigate(
              `/question/${result[questionNumber + 1]?._id}/${
                questionNumber + 1
              }`
            );
          } else {
            navigate("/subscribe");
          }
        } else {
          navigate(`/question/${skipQuestionObject?._id}/${skipQuestionIndex}`);
        }
      } else {
        navigate(redirectURL);
      }
    } else {
      navigate(redirectPrevURL);
    }
  }
  const index_of =
    data.multiselect_answer === false
      ? !singlepreview && !localSinglePreview
      : Object.keys(fileArray).length === 0;

  const ManageQuestions = () => {
    return (
      <>
        <Grid container justifyContent="center" mb={2}>
          {isBack ? (
            <Grid item xs>
              <Button
                onClick={() => handleRedirect(false)}
                className="btn-secondary"
                startIcon={<ArrowBackIcon />}
              >
                <b>Back</b>
              </Button>
            </Grid>
          ) : null}
          {data.skip_que && (
            <Grid item xs>
              <Button
                onClick={() => handleSkipRedirect(true)}
                className="btn-secondary"
              >
                <b>Skip</b>
              </Button>
            </Grid>
          )}
          {index_of && localFileArray.length === 0 ? (
            <Grid item xs>
              <Button disabled endIcon={<ArrowForwardIcon />}>
                <b>Next</b>
              </Button>
            </Grid>
          ) : (
            <Grid item xs>
              <Button
                onClick={() => handleRedirect(true)}
                className="btn-secondary"
                endIcon={<ArrowForwardIcon />}
              >
                <b>Next</b>
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
  };
  const TooltipComponent = () => {
    return (
      <>
        {data.tooltip_heading !== "" && (
          <Container>
            <Tooltip
              arrow
              sx={{ backgroundColor: "#ffffff", marginTop: "30px" }}
              title={
                <Grid>
                  <h6 style={{ textAlign: "center" }}>
                    {data.tooltip_heading}
                  </h6>
                  <p>{data.tooltip_description}</p>
                </Grid>
              }
            >
              <IconButton>
                <ReportRoundedIcon sx={{ height: 50, width: 50 }} />
              </IconButton>
            </Tooltip>
          </Container>
        )}
      </>
    );
  };
  const AnswerLayout = () => {
    return (
      <>
        <Container>
          <div className="file-main">
            <FileUploader
              handleChange={handleFileUploadChange}
              classes="file-upload-place"
              name="file"
              multiple={data.multiselect_answer === true ? true : false}
              children={
                data?.placeholder ? (
                  <div className="file-content_error">{data?.placeholder} </div>
                ) : (
                  <div className="file-content_error">
                    {" "}
                    {"Drag & drop, or click to select files."}{" "}
                  </div>
                )
              }
              onTypeError={(err) => seterror({ ...error, err: err })}
              types={
                data.question_layout_style === "image"
                  ? ["JPG", "PNG", "GIF"]
                  : ["PDF", "TXT"]
              }
              onSizeError={(err) => seterror({ ...error, err: err })}
            />
            <div className="mt-3 ">
              <span className="upload_err d-flex justify-center text-[red]">
                {File[0]?.file?.length >= 4
                  ? data.upload_error === ""
                    ? "- You can upload up to 3 files (5mb max size per file)"
                    : " - " + data.upload_error
                  : ""}
                <br></br>
                {error.err && "-" + error.err}
              </span>
            </div>
          </div>
          {!error.err ? (
            <div
              className={
                data.question_layout_style === "image"
                  ? "d-flex justify-center mt-3"
                  : "mt-3"
              }
            >
              {data.multiselect_answer === false && (
                <>
                  {singlepreview &&
                    (data.question_layout_style === "image" ? (
                      <div className="h-[250px] w-[250px] border-2">
                        <img src={singlepreview} alt="preview" />
                      </div>
                    ) : (
                      <div className="text-center">{singlepreview}</div>
                    ))}
                  {!singlepreview &&
                    localSinglePreview &&
                    (data.question_layout_style === "image" ? (
                      <div className="h-[250px] w-[250px] border-2">
                        <img
                          src={`${process.env.REACT_APP_API_URL}/submission/${localSinglePreview}`}
                          alt="preview"
                        />
                      </div>
                    ) : (
                      <div className="text-center">{localSinglePreview}</div>
                    ))}
                </>
              )}
              {data.multiselect_answer === true && (
                <>
                  {!localFileArray && (
                    <div class="file-container">
                      {Object.keys(fileArray).length <= 3 &&
                        Object.values(fileArray).map((value) => {
                          return data.question_layout_style === "image" ? (
                            <div class="col-md-4">
                              <img
                                src={value}
                                alt="preview"
                                style={{
                                  border: "1px solid #ddd",
                                  borderRadius: "4px",
                                  padding: "5px",
                                }}
                              />
                            </div>
                          ) : (
                            <div className="text-center p-2">{value}</div>
                          );
                        })}
                    </div>
                  )}

                  {localFileArray && (
                    <div class="file-container">
                      {localFileArray.length <= 3 &&
                        localFileArray.map((value) => {
                          return data.question_layout_style === "image" ? (
                            <div class="col-md-4">
                              <img
                                src={`${process.env.REACT_APP_API_URL}/submission/${value}`}
                                alt="preview"
                                style={{
                                  border: "1px solid #ddd",
                                  borderRadius: "4px",
                                  padding: "5px",
                                }}
                              />
                            </div>
                          ) : (
                            <div className="text-center p-2">{value}</div>
                          );
                        })}
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            ""
          )}
        </Container>
      </>
    );
  };
  const LeftStructure = () => {
    return (
      <Grid container justifyContent="center">
        {data.question_add_an_iimage && (
          <Grid item xs={12} md={6} sm={12}>
            <div className="structure-fill img">
              <img src={data.question_add_an_iimage} alt="" srcset="" />
            </div>
          </Grid>
        )}
        <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
          <Grid
            className="radioContainer"
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "0px !important",
              height: "100%",
            }}
          >
            <Grid style={{ width: "100%" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={8}
                  lg={8}
                  sx={{ paddingBlock: "20px" }}
                >
                  <AnswerLayout />
                </Grid>
              </Grid>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const RightStructure = () => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
          <Grid
            className="radioContainer"
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "0px !important",
              height: "100%",
            }}
          >
            <Grid style={{ width: "100%" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={8}
                  lg={8}
                  sx={{ paddingBlock: "20px" }}
                >
                  <AnswerLayout />
                </Grid>
              </Grid>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </Grid>
        {data.question_add_an_iimage && (
          <Grid
            item
            xs={12}
            md={6}
            sm={12}
            // sx={{
            //   minHeight: "100vh",
            //   display: "flex",
            //   alignItems: "center",
            // }}
          >
            <div className="structure-fill img">
              <img src={data?.question_add_an_iimage} alt="" srcset="" />
            </div>
          </Grid>
        )}
      </Grid>
    );
  };
  const CenterStructure = () => {
    return (
      <Grid className="radioContainer" sx={{ marginTop: "0px !important" }}>
        <Grid sx={{ width: "100%" }}>
          <Grid>
            <Typography variant="body1" color="inherit">
              {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
            </Typography>
            <Typography mt={3} variant="h4" color="inherit">
              {data.heading}
            </Typography>
            <Typography mt={3} variant="h6" color="inherit">
              {data.subheading}
            </Typography>
            {data.question_add_an_iimage && (
              <div className="d-flex justify-center mt-3">
                <img
                  src={data?.question_add_an_iimage}
                  height={790 - parseInt(data.image_width)}
                  width={790 - parseInt(data.image_width)}
                  alt=""
                  srcset=""
                />
              </div>
            )}
          </Grid>
          <Grid container justifyContent="center">
            <Grid
              item
              xs={12}
              sm={7}
              md={7}
              lg={7}
              sx={{ paddingBlock: "20px" }}
            >
              <AnswerLayout />
            </Grid>
          </Grid>

          <TooltipComponent />
          <ManageQuestions />
        </Grid>
      </Grid>
    );
  };
  const BackgroundStructure = () => {
    return (
      <>
        <div
          className="back-structure"
          style={{
            zIndex: "0",
            top: "68px",
            bottom: "0",
            left: "0",
            right: "0",
            position: "absolute",
            width: "100%",
            height: "auto",
            opacity: `${data.background_opacity}%`,
            backgroundColor: `${data.background_color}`,
          }}
        ></div>
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${data?.question_add_an_iimage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
            zIndex: "3",
          }}
        >
          <Grid
            className="radioContainer center_part_radioContainer background-section-content"
            sx={{ marginTop: "0px !important" }}
          >
            <Grid sx={{ width: "100%", opacity: "1", zIndex: "3" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <div style={{ paddingBlock: "20px" }}>
                <AnswerLayout />
              </div>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };
  const TopStructure = () => {
    return (
      <Grid className="radioContainer" sx={{ marginTop: "0px !important" }}>
        <Grid sx={{ width: "100%" }}>
          <Typography mt={2} mb={2} variant="body1" color="inherit">
            {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
          </Typography>
          {data.question_add_an_iimage && (
            <div className="d-flex justify-center">
              <img
                src={data?.question_add_an_iimage}
                height={790 - parseInt(data.image_width)}
                width={790 - parseInt(data.image_width)}
                alt=""
                srcset=""
              />
            </div>
          )}
          <Grid style={{ width: "100%" }}>
            <Grid sx={{ marginInline: "5px" }}>
              <Typography mt={3} variant="h4" color="inherit">
                {data.heading}
              </Typography>
              <Typography variant="h6" color="inherit">
                {data.subheading}
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <Grid
                item
                xs={12}
                sm={7}
                md={7}
                lg={7}
                sx={{ paddingBlock: "20px" }}
              >
                <AnswerLayout />
              </Grid>
            </Grid>
            <TooltipComponent />
            <ManageQuestions />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (fileArray || singlepreview) {
      setfileArray({});
      setsinglepreview();
    }
    localSorageData();
  }, [questionNumber]);

  return (
    <>
      {
        {
          center: <CenterStructure />,
          left: <LeftStructure />,
          right: <RightStructure />,
          background: <BackgroundStructure />,
          top: <TopStructure />,
        }[data.image_position]
      }
    </>
  );
};

export default FileUploadQuestion;
