import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import defaultImage from "../../public/default-fe.png";

const ImageWithText = ({
  data,
  questionNumber,
  redirectPrevURL,
  redirectURL,
  topText,
  isBack,
  result_json_data,
  setQuiz_logic_jump_data,
  setQuestionIndex,
  questionIndex,
  quiz,
  result,
}) => {
  const [value, setValue] = useState("");
  const [multiValue, setMultiValue] = useState([]);
  const [productWeight, setProductWeight] = useState([]);
  const [productPoint, setProductPoint] = useState(0);
  const [answerIds, setAnswerIds] = useState([]);
  const [resultWeightIds, setResultWeightIds] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (data.multiselect_answer === true) {
      setQuiz_logic_jump_data((prevData) => {
        const newData = [...prevData];
        newData[questionNumber] = data.leads_to_id;
        return newData;
      });
    }
  }, [data]);

  function localSorageData() {
    var valueArr = JSON.parse(localStorage.getItem("quizData"));
    if (valueArr && valueArr[questionNumber]?.answerId) {
      if (data.multiselect_answer) {
        setMultiValue(valueArr[questionNumber]?.answerId);
      } else {
        setValue(valueArr[questionNumber]?.answerId);
      }
    }
    if (valueArr && valueArr[questionNumber]?.answerIds) {
      setAnswerIds(valueArr[questionNumber]?.answerIds);
    }
    if (valueArr && valueArr[questionNumber]?.product_weight) {
      setProductWeight(valueArr[questionNumber].product_weight);
    }
    if (valueArr && valueArr[questionNumber]?.product_point) {
      setProductPoint(valueArr[questionNumber].product_point);
    }
    if (valueArr && valueArr[questionNumber]?.resultWeightIds) {
      setResultWeightIds(valueArr[questionNumber]?.resultWeightIds);
    }
  }

  function handleAnsValue(event, e) {
    if (data.multiselect_answer === false) {
      setQuiz_logic_jump_data((prevData) => {
        const newData = [...prevData];
        newData[questionNumber] = e.leads_to_id;
        return newData;
      });
    }
    const newAnswerId = [...answerIds];
    const newMultiValuearr = [...multiValue];
    let newAnsWeightarr = [...productWeight];
    if (
      event.target.checked &&
      e.custom_redirect_url &&
      multiValue.length < data.answer_limit
    ) {
      window.open(e.url, "_blank");
    }
    if (e.product_point) {
      if (event.target.checked) {
        const lastValue = productPoint || 0;
        const newSum = lastValue + +e.product_point;
        setProductPoint(newSum);
      } else {
        const lastValue = productPoint || 0;
        if (lastValue >= 0) {
          const newSum = lastValue + -e.product_point;
          setProductPoint(newSum);
        }
      }
    }
    let targetData = e.select_products;
    let allVariantsTemp = [];
    let maxVariantWeightsTemp = null;
    let maxWeight = -Infinity;
    let findRemove_index = -1;
    targetData.forEach((product) => {
      product.variants.forEach((variant) => {
        // let variantCopy = { ...variant };
        // variantCopy.select_option = e.title;
        let variantCopy = {
          ...variant,
          select_option: e.title,
          answer_id: e._id,
        };
        if (
          !newAnsWeightarr.some((v) => v.select_option === e.title) &&
          multiValue.length < data.answer_limit
        ) {
          allVariantsTemp.push(variantCopy);
        } else {
          findRemove_index = newAnsWeightarr.findIndex(
            (v) => v.select_option === e.title
          );
        }
      });
    });

    if (findRemove_index !== -1) {
      newAnsWeightarr = productWeight.splice(findRemove_index, 1);
    }

    if (event.target.checked) {
      setProductWeight([...productWeight, ...allVariantsTemp]);
    } else {
      let removeVariantArray = [];

      e.select_products.forEach((product) => {
        product.variants.forEach((variant) => {
          removeVariantArray.push({ ...variant, answer_id: e._id });
          // removeVariantArray.push(variant);
        });
      });
      const filteredProductWeight = productWeight.filter((pw) => {
        const shouldRemove = removeVariantArray.some(
          (rv) => rv.answer_id === pw.answer_id
        );
        return !shouldRemove;
      });
      setProductWeight(filteredProductWeight);
    }

    const valu_index = newMultiValuearr.indexOf(e.title);
    const product_index = newAnsWeightarr.indexOf(e?.product_weight);
    const newResultWeightIds = [...resultWeightIds];
    const weightIdIndex = newResultWeightIds.indexOf(e.select_result_id);

    if (data.multiselect_answer) {
      if (event.target.checked && newAnswerId.length < data.answer_limit) {
        if (!newAnswerId.includes(e._id)) {
          newAnswerId.push(e._id);
        }
        newResultWeightIds.push(e.select_result_id);
      } else {
        const index = newAnswerId.indexOf(e._id);
        if (index > -1) {
          newAnswerId.splice(index, 1);
          newResultWeightIds.splice(weightIdIndex, 1);
        }
      }
      if (valu_index > -1) {
        newMultiValuearr.splice(valu_index, 1);
        newAnsWeightarr.splice(product_index, 1);
      } else {
        if (
          newMultiValuearr.length < data.answer_limit &&
          !e.custom_redirect_url
        ) {
          newMultiValuearr.push(e.title);
          newAnsWeightarr.push(e?.product_weight);
        } else {
          console.log(`Maximum of ${data.answer_limit} selections reached.`);
        }
      }
      setMultiValue(newMultiValuearr);
      setAnswerIds(newAnswerId);
      setResultWeightIds(newResultWeightIds);
    } else {
      setAnswerIds([e._id]);
      setResultWeightIds([e.select_result_id]);
      if (e.custom_redirect_url) {
        setValue("");
      } else {
        setValue(e.title);
      }
    }
  }

  const handleRedirect = (dir) => {
    if (localStorage.getItem("quizData")) {
      var valueArr = JSON.parse(localStorage.getItem("quizData"));
    } else {
      var valueArr = {};
    }
    valueArr[`${questionNumber}`] = {
      questionId: data._id,
      answerId: data.multiselect_answer ? multiValue : value,
      answerIds: answerIds,
      resultWeightIds: resultWeightIds,
      result_logic: result_json_data.result_logic,
      product_weight:
        result_json_data.result_logic === "Product Weight"
          ? productWeight.length
            ? productWeight
            : null
          : null,
      product_point:
        result_json_data.result_logic === "Points"
          ? productPoint !== 0
            ? productPoint
            : null
          : null,
      product_single:
        result_json_data.result_logic === "Single"
          ? productWeight.length
            ? productWeight
            : null
          : null,
      quizId: data.quiz_id,
    };
    localStorage.setItem("quizData", JSON.stringify(valueArr));
    setValue("");
    setMultiValue("");

    if (dir) {
      if (data.multiselect_answer === true) {
        let backActionArr = localStorage.getItem("back_action")
          ? JSON.parse(localStorage.getItem("back_action"))
          : [];

        if (data.leads_to_id) {
          backActionArr.push(data.leads_to_id);
        } else {
          if (result.length - 1 > questionNumber) {
            backActionArr.push(result[questionNumber + 1]?._id);
          }
        }
        localStorage.setItem("back_action", JSON.stringify(backActionArr));
      } else {
        let backActionArr = localStorage.getItem("back_action")
          ? JSON.parse(localStorage.getItem("back_action"))
          : [];
        const answerObj = data.answers.find((answer) => answer.title === value);
        if (answerObj.leads_to_id) {
          backActionArr.push(answerObj.leads_to_id);
        } else {
          if (result.length - 1 > questionNumber) {
            backActionArr.push(result[questionNumber + 1]?._id);
          }
        }

        localStorage.setItem("back_action", JSON.stringify(backActionArr));
      }
      navigate(redirectURL);
      setQuestionIndex([...questionIndex, data._id]);
    } else {
      if (quiz.logic_jump === true) {
        const back_action_arr = JSON.parse(localStorage.getItem("back_action"));
        const question_id = back_action_arr.pop();
        const secondLastId =
          back_action_arr.length !== 0
            ? back_action_arr[back_action_arr.length - 1]
            : result[0]?._id;
        localStorage.setItem("back_action", JSON.stringify(back_action_arr));
        const prevQuestionIndex = result.findIndex(
          (question) => question._id === secondLastId
        );
        const prevQuestionObject = result.find(
          (question) => question._id === secondLastId
        );

        navigate(`/question/${prevQuestionObject?._id}/${prevQuestionIndex}`);
      } else {
        navigate(redirectPrevURL);
      }
    }
  };

  function handleSkipRedirect(dir) {
    if (localStorage.getItem("quizData")) {
      var valueArr = JSON.parse(localStorage.getItem("quizData"));
    } else {
      var valueArr = {};
    }
    valueArr[`${questionNumber}`] = {
      questionId: data._id,
      answerId: null,
      answerIds: null,
      resultWeightIds: null,
      result_logic: result_json_data.result_logic,
      product_weight: null,
      product_point: null,
      quizId: data.quiz_id,
    };
    localStorage.setItem("quizData", JSON.stringify(valueArr));
    setValue("");
    if (dir) {
      let backActionArr = localStorage.getItem("back_action")
        ? JSON.parse(localStorage.getItem("back_action"))
        : [];

      if (data.leads_to_id) {
        backActionArr.push(data.leads_to_id);
      } else {
        if (result.length - 1 > questionNumber) {
          backActionArr.push(result[questionNumber + 1]?._id);
        }
      }
      localStorage.setItem("back_action", JSON.stringify(backActionArr));
      const skipQuestionIndex = result.findIndex(
        (question) => question._id === data.leads_to_id
      );
      const skipQuestionObject = result.find(
        (question) => question._id === data.leads_to_id
      );
      if (quiz.logic_jump === true) {
        if (data.leads_to_id === "result_page") {
          navigate("/subscribe");
        } else if (!data.leads_to_id) {
          if (result.length - 1 > questionNumber) {
            navigate(
              `/question/${result[questionNumber + 1]?._id}/${
                questionNumber + 1
              }`
            );
          } else {
            navigate("/subscribe");
          }
        } else {
          navigate(`/question/${skipQuestionObject?._id}/${skipQuestionIndex}`);
        }
      } else {
        navigate(redirectURL);
      }
    } else {
      navigate(redirectPrevURL);
    }
  }

  const ManageQuestions = () => {
    return (
      <>
        <Grid container justifyContent="center" mb={2}>
          {isBack ? (
            <Grid item xs>
              <Button
                onClick={() => handleRedirect(false)}
                className="btn-secondary"
                // sx={{ marginRight: "200px" }}
                startIcon={<ArrowBackIcon />}
              >
                <b>Back</b>
              </Button>
            </Grid>
          ) : null}
          {data.skip_que && (
            <Grid item xs>
              <Button
                onClick={() => handleSkipRedirect(true)}
                className="btn-secondary"
                // sx={{ marginRight: "200px" }}
                // startIcon={<ArrowBackIcon />}
              >
                <b>Skip</b>
              </Button>
            </Grid>
          )}

          {value.length === 0 && multiValue?.length === 0 ? (
            <Grid item xs>
              <Button disabled endIcon={<ArrowForwardIcon />}>
                <b>Next</b>
              </Button>
            </Grid>
          ) : (
            <Grid item xs>
              <Button
                onClick={() => handleRedirect(true)}
                className="btn-secondary"
                endIcon={<ArrowForwardIcon />}
              >
                <b>Next</b>
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
  };
  const TooltipComponent = () => {
    return (
      <>
        {data.tooltip_heading !== null && (
          <Container sx={{ paddingBottom: 2 }}>
            <Tooltip
              arrow
              sx={{ backgroundColor: "none" }}
              title={
                <Grid>
                  <h6 style={{ textAlign: "center" }}>
                    {data.tooltip_heading}
                  </h6>
                  <p>{data.tooltip_description}</p>
                </Grid>
              }
            >
              <IconButton>
                <ReportRoundedIcon sx={{ height: 50, width: 50 }} />
              </IconButton>
            </Tooltip>
          </Container>
        )}
      </>
    );
  };
  const DefaultLayout = () => {
    return (
      <>
        <div>
          <div style={{}}>
            <RadioGroup
              sx={{ width: "100%", display: "flex", height: "auto" }}
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={value}
            >
              <Grid
                container
                justifyContent="center"
                sx={{ marginTop: "10px" }}
              >
                {data.answers.map((item) => {
                  return (
                    <Grid
                      item
                      lg={6}
                      md={
                        data.image_position === "left" ||
                        data.image_position === "right"
                          ? 12
                          : 6
                      }
                      sm={6}
                      xs={12}
                      sx={{
                        padding: "0px 15px",
                        display: "flex",
                        textAlign: "start",
                      }}
                    >
                      <FormControlLabel
                        className={`custom-option ${
                          value === item.title ||
                          multiValue.includes(item.title)
                            ? "active"
                            : ""
                        }`}
                        sx={{
                          width: "100%",
                          display: "flex",
                          gap: "20px",
                          alignItems: "center",
                          aspectRatio: "6/2",
                        }}
                        value={item.title}
                        control={
                          data.multiselect_answer ? (
                            <Checkbox
                              className="iwt-custom-radio"
                              checked={multiValue.includes(item.title)}
                              onChange={(e) => handleAnsValue(e, item)}
                            />
                          ) : (
                            <Radio
                              className="iwt-custom-radio"
                              onChange={(e) => handleAnsValue(e, item)}
                            />
                          )
                        }
                        label={
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "20px",
                                marginLeft: "15px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={
                                    item?.answer_add_image
                                      ? item.answer_add_image
                                      : defaultImage
                                  }
                                  style={{
                                    width: {
                                      small: "65px",
                                      large: "95px",
                                    }[data.image_size],
                                    height: {
                                      small: "65px",
                                      large: "95px",
                                    }[data.image_size],
                                  }}
                                  alt=""
                                  srcset=""
                                />
                              </div>
                              <div style={{ maxWidth: "66%" }}>
                                <Typography
                                  style={{ wordBreak: "break-all" }}
                                  className="answer_title"
                                  mt={1}
                                  variant="body2"
                                  color="inherit"
                                >
                                  {item.title}
                                </Typography>
                                <Typography
                                  style={{ wordBreak: "break-all" }}
                                  mt={1}
                                  variant="body2"
                                  color="inherit"
                                >
                                  {item.description}
                                </Typography>
                              </div>
                            </div>
                          </>
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </RadioGroup>
          </div>
        </div>
      </>
    );
  };
  const NarrowLayout = () => {
    return (
      <>
        <div className="narrow-box">
          <div style={{}}>
            <RadioGroup
              className="radi-cont"
              sx={{ width: "100%", display: "flex" }}
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <Grid
                className="narrow-grid"
                container
                justifyContent="center"
                sx={{
                  marginTop: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  display: "flex !important",
                }}
              >
                {data?.answers?.map((item) => {
                  return (
                    <Grid
                      item
                      lg={4}
                      md={
                        data.image_position === "left" ||
                        data.image_position === "right"
                          ? 6
                          : 4
                      }
                      sm={4}
                      xs={12}
                      className="grid-narrow"
                    >
                      <FormControlLabel
                        className={"box_test"}
                        sx={{
                          // minHeight: "240px !important",
                          display: "flex !important",
                          justifyContent: "center",
                          alignItems: "start",
                        }}
                        value={item.title}
                        control={
                          data.multiselect_answer ? (
                            <Checkbox
                              className="iwt-custom-radio"
                              checked={multiValue?.includes(item.title)}
                              onChange={(e) => handleAnsValue(e, item)}
                            />
                          ) : (
                            <Radio
                              className="iwt-custom-radio"
                              onChange={(e) => handleAnsValue(e, item)}
                            />
                          )
                        }
                        label={
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={
                                  item?.answer_add_image
                                    ? item.answer_add_image
                                    : defaultImage
                                }
                                className={`${
                                  value === item?.title ||
                                  multiValue?.includes(item?.title)
                                    ? "narrow_active"
                                    : ""
                                }`}
                                style={{
                                  borderRadius: "100px",
                                  border: "1px solid black",
                                  width: {
                                    small: "65px",
                                    large: "95px",
                                  }[data.image_size],
                                  height: {
                                    small: "65px",
                                    large: "95px",
                                  }[data.image_size],
                                }}
                                alt=""
                                srcset=""
                              />
                            </div>
                            <div>
                              <Typography
                                style={{ wordBreak: "break-all" }}
                                className="answer_title"
                                mt={1}
                                variant="body2"
                                color="inherit"
                              >
                                {item.title}
                              </Typography>
                              <Typography
                                style={{ wordBreak: "break-all" }}
                                mt={1}
                                variant="body2"
                                color="inherit"
                              >
                                {item.description}
                              </Typography>
                            </div>
                          </div>
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </RadioGroup>
          </div>
        </div>
      </>
    );
  };
  const WideLayout = () => {
    return (
      <>
        <div
          className={data.box_size === "small" ? "responsive_box_size" : ""}
          style={{ margin: data.box_size === "large" ? "0px" : "" }}
        >
          <RadioGroup
            sx={{ width: "100%" }}
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={value}
          >
            <Grid container justifyContent="center" sx={{ marginTop: "10px" }}>
              {data?.answers?.map((item) => {
                return (
                  <Grid
                    className={data.box_size === "small" ? "small-box" : ""}
                    xs={data.box_size === "small" ? 12 : 12}
                    lg={data.box_size === "small" ? 3 : 6}
                    md={
                      data.box_size === "small"
                        ? data.image_position === "left" ||
                          data.image_position === "right"
                          ? 6
                          : 3
                        : 6
                    }
                    sm={data.box_size === "small" ? 6 : 6}
                    sx={{
                      padding:
                        data.box_size === "small" ? "0px 10px" : "0px 15px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <FormControlLabel
                      className={`custom-option ${
                        value === item.title || multiValue?.includes(item.title)
                          ? "active"
                          : ""
                      } wide_box_design`}
                      sx={{
                        minHeight: "auto",
                        width: data.box_size === "small" ? "160px" : "93%",
                        display: "flex",
                        justifyContent: data.text_alignment,
                        alignItems: "start",
                        padding: data.box_size === "small" ? "5px" : "20px ",
                      }}
                      value={item.title}
                      control={
                        data.multiselect_answer ? (
                          <Checkbox
                            className="iwt-custom-radio"
                            checked={multiValue?.includes(item.title)}
                            onChange={(e) => handleAnsValue(e, item)}
                          />
                        ) : (
                          <Radio
                            className="iwt-custom-radio"
                            onChange={(e) => handleAnsValue(e, item)}
                          />
                        )
                      }
                      label={
                        <>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: data.text_alignment,
                                margin: "5px 7px",
                              }}
                            >
                              <img
                                src={
                                  item?.answer_add_image
                                    ? item.answer_add_image
                                    : defaultImage
                                }
                                style={{
                                  width: {
                                    small: "65px",
                                    large: "95px",
                                  }[data.image_size],
                                  height: {
                                    small: "65px",
                                    large: "95px",
                                  }[data.image_size],
                                }}
                                alt=""
                                srcset=""
                              />
                            </div>
                            <div
                              style={{
                                textAlign: data.text_alignment,
                                margin: "0px 7px",
                              }}
                            >
                              <Typography
                                style={{
                                  wordBreak: "break-all !important",
                                }}
                                className="answer_title"
                                mt={1}
                                variant="body2"
                                color="inherit"
                              >
                                {item.title}
                              </Typography>
                              <Typography
                                style={{ wordBreak: "break-all" }}
                                mt={1}
                                variant="body2"
                                color="inherit"
                              >
                                {item.description}
                              </Typography>
                            </div>
                          </div>
                        </>
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </RadioGroup>
        </div>
      </>
    );
  };

  const LeftStructure = () => {
    return (
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          md={6}
          sm={12}
          // sx={{
          //   minHeight: "100vh",
          //   display: "flex",
          //   alignItems: "center",
          // }}
        >
          <div className="left-structure-img">
            <img
              src={data.question_add_an_iimage}
              alt=""
              srcset=""
              style={{ width: "100% !important" }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
          <Grid
            className="radioContainer"
            sx={{ marginTop: "0px !important", height: "100%" }}
          >
            <Grid sx={{ width: "100%" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={
                    data.question_layout_style === "Wide" &&
                    data.box_size === "small"
                      ? 6
                      : 12
                  }
                  xs={12}
                >
                  {
                    {
                      Default: <DefaultLayout />,
                      Wide: <WideLayout />,
                      Narrow: <NarrowLayout />,
                    }[data.question_layout_style]
                  }
                </Grid>
              </Grid>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const RightStructure = () => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
          <Grid className="radioContainer" sx={{ marginTop: "0px !important" }}>
            <Grid sx={{ width: "100%" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={
                    data.question_layout_style === "Wide" &&
                    data.box_size === "small"
                      ? 6
                      : 12
                  }
                  xs={12}
                >
                  {
                    {
                      Default: <DefaultLayout />,
                      Wide: <WideLayout />,
                      Narrow: <NarrowLayout />,
                    }[data.question_layout_style]
                  }
                </Grid>
              </Grid>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sm={12}
          // sx={{
          //   height: "100vh",
          // }}
        >
          <div className="right_structure_img">
            <img src={data?.question_add_an_iimage} alt="" srcset="" />
          </div>
        </Grid>
      </Grid>
    );
  };
  const CenterStructure = () => {
    return (
      <Grid
        className="radioContainer center_part_radioContainer"
        sx={{ marginTop: "100px" }}
      >
        <Grid sx={{ width: "100%" }}>
          <Grid sx={{ marginInline: "5px" }}>
            <Typography variant="body1" color="inherit">
              {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
            </Typography>
            <Typography mt={3} variant="h4" color="inherit">
              {data.heading}
            </Typography>
            <Typography mt={3} variant="h6" color="inherit">
              {data.subheading}
            </Typography>
            {data?.question_add_an_iimage !== "" && (
              <div className="d-flex justify-center mt-3">
                <img
                  src={data?.question_add_an_iimage}
                  height={790 - parseInt(data.image_width)}
                  width={790 - parseInt(data.image_width)}
                  alt=""
                  srcset=""
                />
              </div>
            )}
          </Grid>
          <Grid container justifyContent="center">
            <Grid
              item
              lg={data.question_layout_style === "Narrow" ? 10 : 12}
              md={data.question_layout_style === "Narrow" ? 10 : 12}
              sm={
                data.question_layout_style === "Wide" &&
                data.box_size === "small"
                  ? 6
                  : data.question_layout_style === "Narrow"
                  ? 10
                  : 12
              }
              xs={12}
              sx={{ paddingBlock: "20px" }}
            >
              {
                {
                  Default: <DefaultLayout />,
                  Wide: <WideLayout />,
                  Narrow: <NarrowLayout />,
                }[data.question_layout_style]
              }
            </Grid>
          </Grid>
          <TooltipComponent />
          <ManageQuestions />
        </Grid>
      </Grid>
    );
  };
  const BackgroundStructure = () => {
    return (
      <>
        <div
          className="back-structure"
          style={{
            zIndex: "0",
            top: "68px",
            bottom: "0",
            left: "0",
            right: "0",
            position: "absolute",
            width: "100%",
            height: "auto",
            opacity: `${data.background_opacity}%`,
            backgroundColor: `${data.background_color}`,
          }}
        ></div>
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${data?.question_add_an_iimage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
            zIndex: "3",
          }}
        >
          <Grid
            className="radioContainer center_part_radioContainer background-section-content"
            sx={{ marginTop: "0px !important" }}
          >
            <Grid sx={{ width: "100%", opacity: "1", zIndex: "3" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={
                    data.question_layout_style === "Wide" &&
                    data.box_size === "small"
                      ? 6
                      : 12
                  }
                  xs={12}
                >
                  {
                    {
                      Default: <DefaultLayout />,
                      Wide: <WideLayout />,
                      Narrow: <NarrowLayout />,
                    }[data.question_layout_style]
                  }
                </Grid>
              </Grid>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const TopStructure = () => {
    return (
      <Grid
        className="radioContainer center_part_radioContainer"
        sx={{ marginTop: "0px !important" }}
      >
        <Grid sx={{ width: "100%" }}>
          <Typography mt={2} mb={2} variant="body1" color="inherit">
            {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
          </Typography>
          {data?.question_add_an_iimage && (
            <div className="d-flex justify-center">
              <img
                src={data?.question_add_an_iimage}
                height={790 - parseInt(data.image_width)}
                width={790 - parseInt(data.image_width)}
                alt=""
                srcset=""
              />
            </div>
          )}

          <Grid sx={{ marginInline: "5px" }}>
            <Typography mt={3} variant="h4" color="inherit">
              {data.heading}
            </Typography>
            <Typography variant="h6" color="inherit">
              {data.subheading}
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <Grid
              item
              lg={data.question_layout_style === "Narrow" ? 10 : 12}
              md={data.question_layout_style === "Narrow" ? 10 : 12}
              sm={
                data.question_layout_style === "Wide" &&
                data.box_size === "small"
                  ? 6
                  : data.question_layout_style === "Narrow"
                  ? 10
                  : 12
              }
              xs={12}
            >
              {
                {
                  Default: <DefaultLayout />,
                  Wide: <WideLayout />,
                  Narrow: <NarrowLayout />,
                }[data.question_layout_style]
              }
            </Grid>
          </Grid>
          <TooltipComponent />
          <ManageQuestions />
        </Grid>
      </Grid>
    );
  };
  useEffect(() => {
    localSorageData();
  }, [questionNumber]);
  return (
    <>
      {
        {
          center: <CenterStructure />,
          left: <LeftStructure />,
          right: <RightStructure />,
          background: <BackgroundStructure />,
          top: <TopStructure />,
        }[data.image_position]
      }
    </>
  );
};

export default ImageWithText;
