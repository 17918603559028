import {
  Autocomplete,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import InputComponent from "../inputfiled/Input_type_text";
import React, { useState } from "react";

const ThirdPartResult = ({
  qustionData,
  answerOptions,
  setAnswerOptions,
  setIsSaveDisable,
}) => {
  const disabledIds_id = answerOptions.map((option) => option.ans_id);
  const disabledIds = disabledIds_id.flat();
  function handleAnsValue(e, newvalue, reason, details, que_item) {
    const queId = newvalue[0]?.leads_question_id;
    let newAnswerOptionsArr = [...answerOptions];
    if (reason === "selectOption") {
      const selectedOption = que_item.answers.find(
        (option) => option._id === details.option.id
      );
      if (selectedOption) {
        // Check if an entry for the current question already exists
        const existingEntry = newAnswerOptionsArr.find(
          (option) => option.que_id === queId
        );
        if (existingEntry) {
          // Add the new answer ID to the existing ans_id array
          if (!existingEntry.ans_id.includes(selectedOption._id)) {
            existingEntry.ans_id.push(selectedOption._id);
          }
        } else {
          // Create a new entry if it doesn't exist
          newAnswerOptionsArr.push({
            que_id: queId,
            ans_id: [selectedOption._id],
          });
        }
      }
    }
    if (reason == "removeOption") {
      const optionToRemove = que_item.answers.find(
        (option) => option._id === details.option.id
      );
      if (optionToRemove) {
        newAnswerOptionsArr = newAnswerOptionsArr.map((item) => {
          if (item.que_id === que_item._id) {
            return {
              ...item,
              ans_id: item.ans_id.filter((id) => id !== optionToRemove._id),
            };
          }
          return item;
        });
      }
    }
    if (newvalue.length === 0) {
      // Clear all options when newValue is empty
      newAnswerOptionsArr = newAnswerOptionsArr.filter(
        (option) => option.que_id !== que_item._id
      );
    }
    setAnswerOptions(newAnswerOptionsArr);
    setIsSaveDisable(false);
  }

  return (
    <>
      <Grid item xs={12} sm={12} lg={3} md={12} spacing={3} className="mt-4">
        <span
          style={{
            color: "#93999c",
            fontSize: "18px",
            letterSpacing: "-1px",
          }}
        >
          RESULT PATH
        </span>
        <Card className="left_second_rcard">
          <CardContent className="infoq_cardcontent">
            <div className="mx-2">
              {qustionData?.result.map((que_item, que_index) => {
                const selectedAnswers =
                  answerOptions
                    .find((item) => item?.que_id === que_item?._id)
                    ?.ans_id?.map((ans_id) => {
                      const ans_item = que_item.answers.find(
                        (answer) => answer?._id === ans_id
                      );
                      return ans_item
                        ? {
                            label: ans_item?.title,
                            id: ans_item?._id,
                            leads_question_id: ans_item?.leads_question_id,
                          }
                        : null;
                    })
                    .filter((ans) => ans !== null) || [];
                return (
                  <>
                    {!que_item.dont_show && (
                      <>
                        <Typography>
                          <p style={{ marginBottom: "4px", fontSize: "18px" }}>
                            {que_item.heading}
                          </p>
                        </Typography>
                        <div
                          className="result_path_select mb-3 "
                          style={{ width: "100%" }}
                        >
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={que_item.answers.map(
                              (ans_item, ans_index) => {
                                return {
                                  label: ans_item?.title,
                                  id: ans_item._id,
                                  leads_question_id:
                                    ans_item?.leads_question_id,
                                };
                              }
                            )}
                            getOptionDisabled={(option) =>
                              disabledIds.includes(option?.id)
                            }
                            onChange={(e, newvalue, reason, details) =>
                              handleAnsValue(
                                e,
                                newvalue,
                                reason,
                                details,
                                que_item
                              )
                            }
                            value={selectedAnswers}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                style={{ color: "black" }}
                                {...params}
                                className="select_path_border_width"
                                placeholder="Choose an option"
                              />
                            )}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              })}
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default ThirdPartResult;
