
import React, { useEffect, useState, useContext } from "react";
import { FetchContext } from "../../context/FetchContext";
import { AuthContext } from "../../context/AuthContext";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Typography, Box, Modal, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@material-ui/core/IconButton";
import { styled } from '@mui/system';
import Sidenav from "../../component/Sidenav";


const StyledCard = styled(Card)(({ theme }) => ({
  width: 320,
  margin: '20px',
  textAlign: 'center',
  borderRadius: '10px',
  boxShadow: '0px 4px 20px rgba(0,0,0,0.1)',
  position: 'relative', padding: "50px 0px 0px 5px",
}));

const HeaderLabel = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: 'translateX(-50%)',
  borderRadius: '3px',
  padding: '2.5% 1px',
  color: 'white',
  backgroundColor: '#B3E5FC',
  height: '35px',
  width: '320px',
}));


const SquareList = styled('ul')({
  listStyle: 'none',
  padding: 0,
  textAlign: 'left',  // Aligns the text to the left within the card
});

const SquareListItem = styled('li')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
  '&::before': {
    content: '"✔"',  // Unicode character for a checkmark
    display: 'inline-block',
    marginRight: '8px',
    color: 'green',  // Change color if needed
    width: "16px",
    fontSize: "18px"
  },
});
const TitleBar = styled(Box)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  margin: "20px 0px 0px 0px"
}));




const Billing = () => {


  const { authAxios } = useContext(FetchContext);
  const { dispatch } = useContext(AuthContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState("")
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    authAxios
      .get(
        `${process.env.REACT_APP_API_URL}/subscription/details`
      )
      .then((res) => {
        setSubscriptionData(res.data.Status.activeSubscriptions[0])
        setLoading(false)
      })
      .catch((err) => {
        if (err) {
          setLoading(false)
          console.log(err);
        }
      });

  }, [])

  const handlePurchaseAction = (event) => {
    setModalOpen(true);
  };

  const handleCancelSubsricption = (_id) => {
    authAxios
      .delete(
        `${process.env.REACT_APP_API_URL}/subscription/cancel_subscription`
      )
      .then((res) => {
        if (res.data.Status?.appSubscription) {
          toast.success("Subscription Cancelled Successfully");
          dispatch({ type: "LOGOUT" });
          navigate("/page")
        } else {
          toast.error("Subscription Cancelled Unsuccessfully");
        }

      })
      .catch((err) => {
        if (err) {
          toast.error("Error Cancelling Subscription");
          console.log(err);
        }
      });
  };
  const handlePopupClose = () => {
    setModalOpen(false);
  };

  const handlePlanUpgrade = (e) => {
    setLoading(true)
    authAxios
      .get(
        `${process.env.REACT_APP_API_URL}/subscription/update_subscription/${e}`
      )
      .then((res) => {
        if (res?.data?.paymentUrl) { // Assuming the response has a 'url' field
          window.location.href = res.data.paymentUrl; // Redirect to the URL
        } else {
          setLoading(false)
          toast.error("Subscription Created Unsuccessfully");
          console.error('URL not found in the response');
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          toast.error(" Subscription Created Unsuccessfully");
          setLoading(false)
        }
      });
  };

  const plans = [
    // {
    //   title: 'Starter',
    //   price: '$19 per month',
    //   engagements: 'Includes 100 quiz engagements per month + $0.15 per additional engagement',
    //   buttonText: 'Purchase Plan',
    //   features: [
    //     '1 Published quiz',
    //     'No-code quiz templates',
    //     'Personalization',
    //     'Robust quiz logic',
    //     'Email capture functionality',
    //     'Analytics',
    //     'Advanced AI recommendations',
    //   ],
    //   buttonVariant: 'contained',
    //   buttonColor: 'primary',
    //   header: null,
    // },
    {
      title: 'Basic',
      price: '$5 per month',
      engagements: 'Includes 500 quiz engagements per month + $0.08 per additional engagement',
      buttonText: 'Cancel Plan',
      features: [
        'Unlimited quizzes & questions',
        'Logic jumps',
        'Integrates with CRM platforms: Klaviyo',
        'Mailchimp',
        'Omnisend',
        'Active Campaign, & more ',
      ],
      buttonVariant: 'contained',
      buttonColor: 'error',
      header: 'Current plan',
      headerColor: '#B3E5FC',
    },
    {
      title: 'Advanced',
      price: '$50 per month',
      engagements: 'Includes 1000 quiz engagements per month + $0.06 per additional engagement',
      buttonText: 'Purchase Plan',
      features: [
        'Email validator',
        'A/B testing',
        'Integrates with Subscriptions', ' Shopify flow', ' Zapier',
      ],
      buttonVariant: 'contained',
      buttonColor: 'primary',
      header: 'Most popular',
      headerColor: '#A5D6A7',
    },
  ];

  return (
    <Sidenav>
      <TitleBar>
        <Typography variant="h4">Billing</Typography>
      </TitleBar>
      {loading
        ?
        <Typography variant="h4" sx={{ textAlign: "center", marginTop: "10%" }}><CircularProgress color="inherit" /></Typography>
        :
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: "23px" }}>
            {plans.map((plan, index) => (
              <StyledCard key={index} >
                {plan.header && (
                  <HeaderLabel sx={{ backgroundColor: subscriptionData.name == plan.title ? '#B3E5FC' : "#A5D6A7" }}>
                    <Typography variant="caption" sx={{ color: "black" }}>{subscriptionData.name == plan.title ? 'Current plan' : "Most popular"}</Typography>
                  </HeaderLabel>
                )}
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {plan.title}
                  </Typography>
                  <Typography variant="h6" color="textSecondary" className="mb-2">
                    {plan.price}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '20px' }}>
                    {plan.engagements}
                  </Typography>
                  <Button
                    variant={plan.buttonVariant}
                    color={subscriptionData.name == plan.title ? "error" : 'primary'}
                    sx={{ marginBottom: '25px', opacity: "0.7" }}
                    size="medium"
                    onClick={(e) =>
                      subscriptionData.name == plan.title ? handlePurchaseAction("monthly") : handlePlanUpgrade(plan.title)}
                  >
                    {subscriptionData.name == plan.title ? 'Cancel Plan' : 'Upgrade Plan'}
                  </Button>
                  <SquareList>
                    {plan.features.map((feature, i) => (
                      <SquareListItem key={i}>
                        <Typography variant="body2">{feature}</Typography>
                      </SquareListItem>
                    ))}
                  </SquareList>
                </CardContent>
              </StyledCard>
            ))}
          </Box>
          <div>
            <Modal
              open={modalOpen}
              onClose={handlePopupClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="main_modal_div">
                <div
                  id="modal-modal-title"
                  className="product_modal_header py-0 px-2"
                >
                  <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                    Cancel Subsricption
                  </Typography>
                  <Typography>
                    <IconButton
                      sx={{ padding: "0px" }}
                      onClick={handlePopupClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Typography>
                </div>
                <Box className="">
                  <div className="product_modal_body">
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, padding: " 10px 20px" }}
                    >
                      Are you sure want to cancel subsricption ?
                    </Typography>
                    <div
                      style={{
                        padding: "10px 0px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "start",
                      }}
                    ></div>
                  </div>
                </Box>
                <div
                  className="product_modal_footer"
                  style={{
                    padding: "10px 10px",

                    gap: "15px",
                  }}
                >
                  <Button
                    onClick={handlePopupClose}
                    className="delete_modal_no"
                    variant="contained"
                  >
                    No
                  </Button>
                  <Button
                    onClick={(e) => handleCancelSubsricption(e)}
                    className="product_modal_save"
                    variant="contained"
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        </>
      }

    </Sidenav>

  );
};

export default Billing;
