import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { CButton, CFormLabel } from "@coreui/react";
import InfoIcon from "@mui/icons-material/Info";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import { styled } from "@mui/material/styles";
import defaultImage from "../../../../public/default-fe.png";

const RightImageCard = ({
  imgquedata,
  imgansdata,
  question_add_an_iimage,
  allquestion,
  question_show_iimage,
  answer_show_image,
}) => {
  let text_align = imgquedata?.text_alignment;
  let textAlign =
    text_align === "left" ? "start" : text_align === "right" ? "end" : "center";

  const containerStyle = {
    position: "relative",
    width: "100%",
    // height: '100vh',
    backgroundImage: `url(${question_show_iimage ? question_show_iimage : ""})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: `${imgquedata?.background_color}`,
    opacity: `${imgquedata.background_opacity}%`,
  };
  return (
    <>
      <Grid item xs={12} sm={12} lg={8} md={12} spacing={3} className="mt-4">
        <Card
          sx={imgquedata.image_position === "background" && containerStyle}
          className="right_quiz_card"
        >
          {imgquedata.image_position === "background" && (
            <div style={overlayStyle} className="backDiv"></div>
          )}
          <CardContent>
            <div item xs={12} className="image_card_top">
              {!imgquedata.hide_progress_bar && (
                <Typography
                  variant="subtitle1"
                  className="question_length"
                  component="div"
                >
                  {allquestion?.result?.length +
                    1 +
                    " of " +
                    (allquestion?.result?.length + 1)}
                </Typography>
              )}

              {(imgquedata.image_position === "top" ||
                imgquedata.image_position === "left") &&
                question_show_iimage.length !== 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={question_show_iimage}
                      width={
                        imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}
              <Typography sx={{ mb: 1 }} className="right_imgque_heading">
                {imgquedata.heading}
              </Typography>
              <Typography sx={{ mb: 1.5 }} className="right_imgque_subheading">
                {imgquedata.subheading}
              </Typography>
              {imgquedata.image_position === "center" &&
                question_show_iimage.length !== 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={question_show_iimage}
                      width={
                        imgquedata.image_width === 13
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        imgquedata.image_width === 13
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}
              <div
                className="option_main_contain"
                style={{
                  ...(imgquedata.box_size === "large" &&
                  imgquedata.question_layout_style === "Wide"
                    ? {
                        width: "64%",
                        margin: "20px auto",
                      }
                    : {}),
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={{ display: "flex" }}
                >
                  {imgansdata.map((item, index) => (
                    <Grid
                      item
                      wrap="nowrap"
                      xs={
                        imgquedata.box_size == "large" ||
                        imgquedata.question_layout_style === "Default"
                          ? 12
                          : 6 
                      }
                      sm={
                        imgquedata.box_size == "large" ||
                        imgquedata.question_layout_style === "Default"
                          ? 12
                          : 6
                      }
                      md={
                        imgquedata.box_size == "large" ||
                        imgquedata.question_layout_style === "Default"
                          ? 6
                          : 3
                      }
                      
                      sx={{ display: "flex" }}
                    >
                      {/* ******************************************************************************WIDE LAYOUT START***************************** */}
                      {imgquedata.question_layout_style === "Wide" && (
                        <div
                          className="wide_contained_admin"
                          style={{ display: "flex" }}
                        >
                          <div
                            className="admin_wide_box"
                            style={{
                              width:
                                imgquedata.box_size === "small"
                                  ? "160px"
                                  : "210px",
                            }}
                          >
                            <div
                              className="admin_inner_box_space"
                              style={{ alignItems: textAlign }}
                            >
                              {answer_show_image[index] ? (
                                <div className="wide_option_img">
                                  <img
                                    className="narrow-img-custom"
                                    src={answer_show_image[index]}
                                    style={{
                                      width:
                                        imgquedata.image_size === "small"
                                          ? "80px"
                                          : "110px",
                                    }}
                                  />
                                </div>
                              ) : (
                                <img
                                  className="narrow-img-custom"
                                  src={defaultImage}
                                  style={{
                                    width:
                                      imgquedata.image_size === "small"
                                        ? "80px"
                                        : "110px",
                                  }}
                                />
                              )}
                              <p className="option_title">{item.title}</p>
                              <p
                                className="option_description"
                                style={{ textAlign: textAlign }}
                              >
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* ******************************************************************************WIDE LAYOUT END***************************** */}
                      {/* ******************************************************************************NARROW LAYOUT START***************************** */}
                      {imgquedata.question_layout_style === "Narrow" && (
                        <div className="narrow_contained_admin">
                          <div className="narrow_contain_center">
                            <div
                              className="narrow_admin_round_box"
                              style={{
                                width:
                                  imgquedata.image_size === "small"
                                    ? "45%"
                                    : "60%",
                                height:
                                  imgquedata.image_size === "small"
                                    ? "85px"
                                    : "110px",
                              }}
                            >
                              {answer_show_image[index] ? (
                                <div className="">
                                  <img
                                    className="narrow_admin_image"
                                    src={answer_show_image[index]}
                                  />
                                </div>
                              ) : (
                                <img
                                  className="narrow_admin_image"
                                  src={defaultImage}
                                />
                              )}
                            </div>
                            <div className="admin_narrow_box">
                              <p className="option_title">{item.title}</p>
                              <p
                                className="option_description"
                                style={{ textAlign: textAlign }}
                              >
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* ******************************************************************************NARROW LAYOUT END***************************** */}
                      {/* ******************************************************************************DEFAULT LAYOUT START***************************** */}
                      {imgquedata.question_layout_style === "Default" && (
                        <div className="default_contained_admin">
                          <div className="default_box">
                            <div
                              className="admin_inner_default_box_space"
                              style={{
                                width:
                                  imgquedata.image_size === "small"
                                    ? "28%"
                                    : "38%",
                                height:
                                  imgquedata.image_size === "small"
                                    ? "40px"
                                    : "110px",
                              }}
                            >
                              {answer_show_image[index] ? (
                                <div className="">
                                  <img
                                    className=""
                                    src={answer_show_image[index]}
                                  />
                                </div>
                              ) : (
                                <img className="" src={defaultImage} />
                              )}
                            </div>
                            <div className="admin_default_box">
                              <p className="option_title">{item.title}</p>
                              <p
                                className="option_description"
                                style={{ textAlign: textAlign }}
                              >
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* ******************************************************************************DEFAULT LAYOUT END***************************** */}
                    </Grid>
                  ))}
                </Grid>
              </div>
              {imgquedata.image_position === "right" &&
                question_show_iimage.length !== 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={question_show_iimage}
                      width={
                        imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}
              <div className="info_icon mt-12">
                {imgquedata.tooltip_heading ? (
                  <>
                    <Container sx={{ paddingBottom: 2 }}>
                      <Tooltip
                        arrow
                        sx={{
                          backgroundColor: "transparent",
                          ":hover": "none",
                        }}
                        title={
                          <Grid>
                            <h5 style={{ textAlign: "center" }}>
                              {imgquedata?.tooltip_heading}
                            </h5>
                            <p>{imgquedata?.tooltip_description}</p>
                          </Grid>
                        }
                      >
                        <IconButton>
                          <ReportRoundedIcon
                            sx={{ fontSize: "50px !important" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Container>
                  </>
                ) : (
                  ""
                )}
              </div>
              <Grid container className="" justifyContent="center">
                <Grid
                  className="back"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <WestIcon /> <span className="mx-2"> BACK </span>
                </Grid>
                {imgquedata.skip_que && (
                  <Grid
                    className="back"
                    xs
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span className="mx-2"> SKIP </span>
                  </Grid>
                )}

                <Grid
                  className="next"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <span className="mx-2 "> NEXT </span> <EastIcon />
                </Grid>
              </Grid>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default RightImageCard;
