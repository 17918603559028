import { Box, Chip, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Buttton from "../../../Button/Buttton";
import Pagestepper from "../../../intropage/Pagestepper";
import Sidenav from "../../../Sidenav";
import Questionsecondcard from "../../../question/AddQuestion/Questionsecondcard";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FetchContext } from "../../../../context/FetchContext";
import { AuthContext } from "../../../../context/AuthContext";
import EditLeftFileUpload from "./EditLeftFileUpload";
import EditRightFileupload from "./EditRightFileupload";
import { toast } from "react-hot-toast";
import CreateQuestionTitle from "../../../Title/Title";

const EditFileUpload = () => {
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const { state } = useContext(AuthContext);
  const navigate = useNavigate();

  const [question_add_an_iimage, setquestion_add_an_iimage] = React.useState(
    []
  );
  const [question_show_iimage, setquestion_show_iimage] = React.useState([]);
  const [imgquedata, setimgquedata] = React.useState([]);
  const [validation, setvalidation] = React.useState(false);
  const [isSaveDisable, setIsSaveDisable] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [allquestion, setallquestion] = React.useState([]);
  const [allQuelist, setAllQueList] = useState([]);
  const quiz = state?.allquiz?.filter(
    (item) => item?._id === imgquedata?.quiz_id
  )[0];
  const preview = () => {
    if (quiz.disable_intro === true) {
      navigate(`/preview/enable_intropage/${imgquedata?.quiz_id}`);
    } else {
      navigate(`/preview/${imgquedata?.quiz_id}`);
    }
  };

  useEffect(() => {
    authAxios
      .post(
        `${process.env.REACT_APP_API_URL}/question/get_question_answerdata/${id}`
      )
      .then((res) => {
        setimgquedata(res.data.questiondata);
        setallquestion(res.data);
        const allQuestion_list = res.data.result;
        const result = allQuestion_list.map((item, index) => ({
          label: `Question ${index + 1}. ${item.heading}`,
          id: item._id,
        }));
        setAllQueList(result);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }, []);

  const questionsave = () => {
    const formData = new FormData();
    formData.append("imgquedata", JSON.stringify(imgquedata));
    formData.append("question_add_an_iimage", question_add_an_iimage);
    if (imgquedata.heading === "") {
      toast.error("Question heading can't be blank ");
      setvalidation(true);
    } else {
      setLoading(true);
      authAxios
        .post(
          `${process.env.REACT_APP_API_URL}/question/edit_question_answer/${id}`,
          formData
        )
        .then((res) => {
          // setimgquedata(res.data);
          if (res.data.msg === "success") {
            setLoading(false);
            setimgquedata(res.data);
            navigate(`/quizzes/intropage/${quiz?._id}`);
          }
        })
        .catch((err) => {
          if (err) {
            setLoading(false);
            toast.error(err);
            console.log(err);
          }
        });
    }
  };

  return (
    <div>
      <Sidenav>
        <div className="mx-2">
          <CreateQuestionTitle
            link={`/quizzes/intropage/${quiz?._id}`}
            quiz_name={quiz?.quiz_name}
            is_publish={quiz?.is_publish}
            preview={preview}
            questionsave={questionsave}
            isSaveDisable={isSaveDisable}
            loading={loading}
          />
        </div>
        <div className="mt-4 mx-2">
          <Pagestepper quiz={quiz} />
        </div>
        <div className="mt-4 mx-2">
          <Questionsecondcard
            allquestion={allquestion}
            imgquedata={imgquedata}
          />
        </div>
        <Box className="d-flex mx-2">
          <Grid container spacing={3}>
            <EditLeftFileUpload
              imgquedata={imgquedata}
              setimgquedata={setimgquedata}
              question_add_an_iimage={question_add_an_iimage}
              setquestion_add_an_iimage={setquestion_add_an_iimage}
              question_show_iimage={question_show_iimage}
              setquestion_show_iimage={setquestion_show_iimage}
              validation={validation}
              setvalidation={setvalidation}
              setIsSaveDisable={setIsSaveDisable}
              allQuelist={allQuelist}
              allquestion={allquestion}
            />
            <EditRightFileupload
              imgquedata={imgquedata}
              setimgquedata={setimgquedata}
              question_add_an_iimage={question_add_an_iimage}
              setquestion_add_an_iimage={setquestion_add_an_iimage}
              question_show_iimage={question_show_iimage}
              allquestion={allquestion}
            />
          </Grid>
        </Box>
      </Sidenav>
    </div>
  );
};

export default EditFileUpload;
