import React, { useContext, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import Iconify from "../iconify";
import toast, { Toaster } from "react-hot-toast";
import { Form, Formik, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../context/AuthContext";
import { LOGIN } from "../../context/constatnts";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Loginform() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const [showPassword, setshowPassword] = useState([]);
  const [dashShow, setDashShow] = useState(true);
  // Parse the query parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const timestamp = searchParams.get("timestamp");
    const session = searchParams.get("session");
    const host = searchParams.get("host");
    const shop = searchParams.get("shop");
    const hmac = searchParams.get("hmac");
    // const search = searchParams.get('search');
    if (shop) {
      const getdata = {
        timestamp,
        session,
        host,
        shop,
        hmac,
      };
      setDashShow(true);
      const fetchData = async () => {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/shopihy/Auth`,
          getdata
        );

        console.log("response------>", response);
        if (response.status === 200 || response.status === 201) {
          if (response.data?.error) {
            dispatch({ type: "LOGOUT" });
            toast?.error(response.data?.error);
            navigate("/authentication");
          } else {
            const { statusCode, massage, token, loginadmin } = response.data;
            dispatch({
              type: LOGIN,
              payload: {
                token,
                massage,
                statusCode,
                loginadmin,
              },
            });
            navigate("/dashboard");
          }
        } else {
          dispatch({ type: "LOGOUT" });
          toast?.error(response?.error);
          navigate("/authentication");
        }
      };
      fetchData();
    } else {
      setDashShow(false);
    }
  }, []);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,

    onSubmit: async (formData) => {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/login`, formData)
        .then((response) => {
          const {
            data: { statusCode, massage, token, loginadmin },
          } = response;
          if (statusCode === 200) {
            dispatch({
              type: LOGIN,
              payload: {
                token,
                massage,
                statusCode,
                loginadmin,
              },
            });
            toast.success(response?.data?.massage);
            navigate("/dashboard");
          } else {
            toast?.error(response?.data?.massage);
          }
        })
        .catch((err) => {
          if (err) {
            toast.error(err);
          }
        });
    },
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      {dashShow && (
        <>
          <div className="d-flex justify-content-center">
            <div className="mt-5 ">
              <div>
                <Typography variant="h6">
                  <Skeleton animation="wave" width={180} />
                </Typography>
                <Typography
                  variant="body2"
                  className="welcome_description"
                  sx={{}}
                >
                  <Skeleton animation="wave" width={250} />
                </Typography>
              </div>

              <div className="recent_edit_card">
                <Card
                  variant="outlined"
                  sx={{
                    borderRadius: "10px !important",
                    width: "100%",
                    padding: "15px",
                  }}
                >
                  <CardContent sx={{ padding: "0px !important" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        padding: "15px 25px",
                        color: "#303030e3 !important",
                      }}
                    >
                      <b>
                        <Skeleton animation="wave" width={200} />
                      </b>
                    </Typography>
                    <div
                      className="card_img_fame"
                      style={{ border: "none", marginTop: "15px" }}
                    >
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={940}
                        height={418}
                      />
                    </div>
                    <hr style={{ margin: "0px" }}></hr>
                    <div className="quiz_title_contain">
                      <div className="title_and_button">
                        <div>
                          <div className="chip_content">
                            <p className="quiz_heading">
                              <Skeleton animation="wave" width={180} />
                            </p>
                            <div>
                              <Skeleton animation="wave" width={80} />
                              {/* <Chip
                        className="unlive_chip"
                        label="Unpublished"
                        size="small"
                      /> */}
                            </div>
                          </div>
                          <p className="update_quiz_date">
                            <Skeleton animation="wave" width={280} />
                          </p>
                        </div>
                        <div>
                          <Skeleton animation="wave" width={110} height={60} />
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </>
      )}

      {!dashShow && (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  {...getFieldProps("email")}
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  {...getFieldProps("password")}
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputLabelProps={{ shrink: true }}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setshowPassword(!showPassword)}
                          edge="end"
                        >
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Stack sx={{ my: 1, width: "100%" }}>
                  <Link
                    to={"/forgot-pass"}
                    variant="subtitle2"
                    underline="hover"
                  >
                    Forgot password?
                  </Link>
                </Stack>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  loading={isSubmitting}
                >
                  Sign In
                </Button>
              </div>
              <Box mt={8}></Box>
            </Container>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
