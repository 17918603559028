import { Box, Divider, Grid } from "@material-ui/core";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { FetchContext } from "../../../context/FetchContext";
import Buttton from "../../Button/Buttton";
import Sidenav from "../../Sidenav";
import LeftSideCArd from "./LeftSideCArd";
import RightSideCard from "./RightSideCard";
import toast from "react-hot-toast";

const EditIntroQuizPage = () => {
  const { authAxios } = useContext(FetchContext);
  const { state, dispatch } = useContext(AuthContext);

  const { id } = useParams();
  // console.log("id-->");

  const navigate = useNavigate();
  const [quiz_add_image, setquiz_add_image] = React.useState([]);
  const [quiz_desktop_image, setquiz_desktop_image] = React.useState([]);

  const [quiz_show_image, setquiz_show_image] = React.useState([]);
  const [quiz_show_desktop_image, setquiz_show_desktop_image] = React.useState(
    []
  );

  const [getdata, setgetdata] = useState([
    {
      heading: "",
      subheading: "",
      button_text: "",
      content_alignment: "center",
      background_type: "Image",
      quiz_add_image: "",
      quiz_desktop_image: "",
      quiz_mobile_image: "",
      overlay_color: "",
      overlay_opacity: "",
      disable_intro: false,
      image_width: 0,
      user_name: state?.loginadmin?.name || "",
      user_id: state?.loginadmin?._id || "",
    },
  ]);

  const [loading, setLoading] = useState(true);
  const [isSaveDisable, setIsSaveDisable] = React.useState(true);
  const [alldata, setalldata] = useState([]);

  useEffect(() => {
    authAxios
      .post(`${process.env.REACT_APP_API_URL}/quiz/edit_quiz_data/${id}`)
      .then((res) => {
        const data = res.data?.data;
        setquiz_show_image(data?.quiz_add_image);
        setquiz_show_desktop_image(data?.quiz_desktop_image);
        // setgetdata(data);
        setLoading(false);
        setgetdata({
          heading: data?.heading || "",
          subheading: data?.subheading || "",
          button_text: data?.button_text || "",
          content_alignment: data?.content_alignment || "center",
          background_type: data?.background_type || "Image",
          quiz_add_image: data?.quiz_add_image || null,
          quiz_desktop_image: data?.quiz_desktop_image || "",
          quiz_mobile_image: data?.quiz_mobile_image || "",
          overlay_color: data?.overlay_color || "",
          overlay_opacity: data?.overlay_opacity || "",
          image_width: data?.image_width || 0,
          disable_intro: data?.disable_intro || false,
          user_name: state?.loginadmin?.name || "",
          user_id: state?.loginadmin?._id || "",
        });
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }, []);
  function handleStateUpdate() {
    authAxios
      .get(`${process.env.REACT_APP_API_URL}/quiz/all_quiz`)
      .then((res) => {
        const { data } = res;
        dispatch({
          type: "ALL_QUIZ",
          payload: {
            allquiz: data.data,
          },
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        setLoading(false);
      });
    return () => {
      authAxios;
    };
  }
  const introquizsave = () => {
    if (
      getdata?.button_text == null
        ? getdata?.button_text !== null
        : getdata?.button_text !== ""
    ) {
      const formData = new FormData();
      formData.append("getdata", JSON.stringify(getdata));

      if (quiz_add_image.length !== 0) {
        formData.append("quiz_add_image", quiz_add_image);
      }

      if (quiz_desktop_image.length !== 0) {
        formData.append("quiz_desktop_image", quiz_desktop_image);
      }

      authAxios
        .post(`${process.env.REACT_APP_API_URL}/quiz/edit_quiz/${id}`, formData)
        .then((res) => {
          setalldata(res.data);
          handleStateUpdate();  
        })
        .catch((err) => {
          if (err) {
            console.log(err);
          }
        });
      if (getdata.disableintro == true) {
        navigate(`/questiontype/${id}`);
      } else {
        navigate(`/quizzes/intropage/${id}`);
      }
    } else {
      toast?.error("Button Text can't be blank");
    }
  };

  useEffect(() => {}, [id]);
  if (quiz_show_image) {
    var editaddimage = true;
  }
  return (
    <>
      <Sidenav>
        {loading ? (
          <div sx={{ display: "flex" }}>
            <Backdrop
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        ) : (
          <div>
            <Typography variant="h4" gutterBottom>
              Edit Intro Page
            </Typography>
            <Box className="d-flex mx-2">
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={4}
                  md={12}
                  spacing={3}
                  className="mt-4"
                >
                  <LeftSideCArd
                    alldata={alldata}
                    getdata={getdata}
                    quiz_show_image={quiz_show_image}
                    editaddimage={editaddimage}
                    setgetdata={setgetdata}
                    quiz_add_image={quiz_add_image}
                    setquiz_add_image={setquiz_add_image}
                    setquiz_show_image={setquiz_show_image}
                    setIsSaveDisable={setIsSaveDisable}
                    quiz_desktop_image={quiz_desktop_image}
                    setquiz_desktop_image={setquiz_desktop_image}
                    setquiz_show_desktop_image={setquiz_show_desktop_image}
                    quiz_show_desktop_image={quiz_show_desktop_image}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={8} md={12} className="mt-4">
                  <RightSideCard
                    alldata={alldata}
                    getdata={getdata}
                    editaddimage={editaddimage}
                    setgetdata={setgetdata}
                    quiz_add_image={quiz_add_image}
                    setquiz_add_image={setquiz_add_image}
                    quiz_show_image={quiz_show_image}
                    setquiz_show_image={setquiz_show_image}
                    setquiz_desktop_image={setquiz_desktop_image}
                    quiz_desktop_image={quiz_desktop_image}
                    setquiz_show_desktop_image={setquiz_show_desktop_image}
                    quiz_show_desktop_image={quiz_show_desktop_image}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider
              style={{ background: "gray" }}
              className="mt-4 mx-2"
            ></Divider>
            <Grid className="float-right mx-2 mt-4 pb-4">
              <Buttton
                handleClick={introquizsave}
                title={"save"}
                disabled={isSaveDisable}
              />
            </Grid>
          </div>
        )}
      </Sidenav>
    </>
  );
};

export default EditIntroQuizPage;
