import React, { useContext, useEffect } from "react";
import Sidenav from "../Sidenav";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import {
  Backdrop,
  Card,
  Chip,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import Buttton from "../Button/Buttton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "../dropdown/Select";

// import CardIntro from "./CardIntro";
// import Logiccard from "./Logiccard";
import { AuthContext } from "../../context/AuthContext";
import { FetchContext } from "../../context/FetchContext";
import Pagestepper from "../intropage/Pagestepper";
import { CardContent } from "@mui/material";
// import InputComponent from "../inputfiled/Input_type_text";
import InputComponent from "../inputfiled/Input_type_text";

import { toast } from "react-hot-toast";
import CreateQuestionTitle from "../Title/Title";
import Logiccard from "../intropage/Logiccard";
// import CardResult from "./CardResult";

const Settings = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const { state } = useContext(AuthContext);
  const quiz = state?.allquiz?.filter((item) => item?._id === id)[0];
  console.log("quiz--->", quiz);

  const [loading, setLoading] = React.useState(true);
  const [isSaveDisable, setIsSaveDisable] = React.useState(true);
  const [question, setQuestion] = React.useState("");
  const [quizData, setQuizData] = React.useState({
    quiz_name: "",
  });
  const [validation, setValidation] = React.useState({
    quiz_name: "",
  });

  const [resultsettingdata, setresultsettingdata] = React.useState({
    result_logic: "Basic(Default)",
    products_limit: "1",
  });

  function getResultData() {
    authAxios
      .post(`${process.env.REACT_APP_API_URL}/question/show_question/${id}`, id)
      .then((res) => {
        console.log("quiz data----->", res.data);
        setQuestion(res.data);
        setQuizData(res.data.quiz);
        if (res.data.quiz.result_json !== null) {
          setresultsettingdata(res.data.quiz.result_json);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }

  const preview = () => {
    if (quiz.disable_intro === true) {
      navigate(`/preview/enable_intropage/${id}`);
    } else {
      navigate(`/preview/${id}`);
    }
  };
  React.useEffect(() => {
    getResultData();
  }, [id]);

  const questionsave = async () => {
    if (quizData?.quiz_name !== "") {
      setValidation({
        quiz_name: false,
      });
      const formData = new FormData();
      formData.append("resultsettingdata", JSON.stringify(resultsettingdata));

      const formDatas = new FormData();
      formDatas.append("getdata", JSON.stringify(quizData));

      setLoading(true);

      try {
        const res1 = await authAxios.post(
          `${process.env.REACT_APP_API_URL}/quiz/set_result/${id}`,
          formData
        );
        const res2 = await authAxios.post(
          `${process.env.REACT_APP_API_URL}/quiz/edit_quiz/${id}`,
          formDatas
        );

        if (res1.data.msg === "success") {
          // navigate(`/quizzes/allquiz`)
          setLoading(false);
        }
      } catch (err) {
        toast.error(err.message || "An error occurred");
        console.error(err);
        setLoading(false);
      }
    } else {
      setValidation({
        quiz_name: true,
      });
      toast.error("Quiz Name can't be blank");
    }
  };

  return (
    <Sidenav className="container-fluid">
      {loading ? (
        <div sx={{ display: "flex" }}>
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : (
        <>
          <CreateQuestionTitle
            link={`/quizzes/allquiz`}
            quiz_name={
              quizData?.quiz_name === "" ? quiz?.quiz_name : quizData?.quiz_name
            }
            is_status={quiz?.is_status}
            preview={preview}
            questions={question}
            questionsave={questionsave}
            isSaveDisable={isSaveDisable}
            loading={loading}
            viewTitle={true}
            is_publish={quiz?.is_publish}
          />
          <Grid container justifyContent="center">
            <Grid item lg={11} md={12} sm xs>
              <div className="mt-7">
                <Pagestepper setting="setting" />
              </div>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item lg={11}>
              <div className="mt-12">
                <span
                  style={{
                    color: "#93999c",
                    fontSize: "18px",
                    letterSpacing: "-1px",
                  }}
                >
                  SETTINGS
                </span>
                <Grid container>
                  <Grid item className="mt-4" lg={6} md={6} sm={12} xs={12}>
                    <Card className="left_second_rcard mr-2">
                      <CardContent className="infoq_cardcontent">
                        <div className="mx-2">
                          <InputComponent
                            label_name={"Quiz name"}
                            onChange={(e) => {
                              setIsSaveDisable(false);
                              setQuizData({
                                quiz_name: e.target.value,
                              });
                            }}
                            value={quizData?.quiz_name}
                          />
                        </div>
                        {validation.quiz_name === true ? (
                          <div className="text-[red] mx-2 mt-[-8px]">
                            Quiz Name can't be blank
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="mx-2 mt-4 ">
                          <Select
                            dropdown_text={"Results logic"}
                            values={[
                              "Basic(Default)",
                              "Product Weight",
                              "Points",
                              "Single",
                              "Result Weight",
                            ]}
                            onChange={(e) => {
                              setresultsettingdata({
                                ...resultsettingdata,
                                result_logic: e.target.value,
                              });
                              setIsSaveDisable(false);
                            }}
                            value={resultsettingdata?.result_logic}
                          />
                        </div>
                        {(resultsettingdata?.result_logic ===
                          "Product Weight" ||
                          resultsettingdata?.result_logic === "Single") && (
                            <div className="mx-2">
                              <InputComponent
                                label_name={"Recommended products limit"}
                                value={resultsettingdata?.products_limit}
                                onChange={(e) => {
                                  setresultsettingdata({
                                    ...resultsettingdata,
                                    products_limit: e.target.value,
                                  });
                                  // setvalidation(false);
                                  setIsSaveDisable(false);
                                }}
                                min="1"
                                type="number"
                              />
                            </div>
                          )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item className="mt-4" lg={6} md={6} sm={12} xs={12}>
                    {question && (
                      <Card className="left_second_rcard ml-2">
                        <div className="mt-4 mx-2">
                          <Logiccard quizquestion={question} />
                        </div>
                      </Card>
                    )}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </Sidenav>
  );
};

export default Settings;
