import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  Autocomplete,
  Container,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { CFormLabel } from "@coreui/react";
import InfoIcon from "@mui/icons-material/Info";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { styled } from "@mui/material/styles";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";


const RightSelectCard = ({
  imgquedata,
  imgansdata,
  question_add_an_iimage,
  allquestion,
  question_show_iimage,
  answer_show_image,
}) => {
  const containerStyle = {
    position: "relative",
    width: "100%",
    // height: '100vh',
    backgroundImage: `url(${question_show_iimage ? question_show_iimage : ""})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: `${imgquedata?.background_color}`,
    opacity: `${imgquedata.background_opacity}%`,
  };

  return (
    <>
      <Grid item xs={12} sm={12} lg={8} md={12} spacing={3} className="mt-4">
        <Card
          sx={imgquedata.image_position === "background" && containerStyle}
          className="right_quiz_card"
        >
          {imgquedata.image_position === "background" && (
            <div style={overlayStyle} className="backDiv"></div>
          )}
          <CardContent>
            <Grid className="image_card_top">
              {!imgquedata.hide_progress_bar && (
                <Typography
                  variant="h6"
                  className="question_length"
                  component="div"
                >
                  {allquestion?.result?.length +
                    1 +
                    " of " +
                    (allquestion?.result?.length + 1)}
                </Typography>
              )}
              {(imgquedata.image_position === "top" ||
                imgquedata.image_position === "left") &&
                question_show_iimage.length !== 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={question_show_iimage}
                      width={
                        imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}
              <Typography sx={{ mb: 1 }} className="right_imgque_heading">
                {imgquedata.heading}
              </Typography>
              <Typography sx={{ mb: 1.5 }} className="right_imgque_subheading">
                {imgquedata.subheading}
              </Typography>
              {imgquedata.image_position === "center" &&
                question_show_iimage.length !== 0 && (
                  <div
                    className="mt-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src={question_show_iimage}
                      width={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}

              <div
                className="option_main_contain"
                style={{
                  margin:
                    imgquedata.box_size == "small"
                      ? "15px 10px"
                      : "15px 10px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={{ display: "flex" }}
                >
                  {/* ******************************************************************************ANSWER LAYOUT START***************************** */}

                  <div className={"d-flex mt-4 center_small_image_box"}>
                    <div
                      className="select_border"
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      {imgquedata.multiselect_answer === true && (
                        <Autocomplete
                          multiple
                          autoHighlight
                          id="tags-outlined"
                          options={imgansdata.map((item, index) => {
                            return {
                              label: item?.title
                                ? item?.title
                                : `option${" "}${index + 1}`,
                            };
                          })}
                          filterSelectedOptions
                          sx={{ width: 600, height: 50 }}
                          renderInput={(params) => (
                            <TextField
                              style={{ color: "black" }}
                              {...params}
                              className="select_border_width"
                              placeholder={
                                imgquedata.placeholder
                                  ? imgquedata.placeholder
                                  : "Choose an option"
                              }
                            />
                          )}
                        />
                      )}
                      {imgquedata.multiselect_answer === false && (
                        <Autocomplete
                          autoHighlight
                          id="outlined-size-small"
                          options={imgansdata?.map((item, index) => {
                            return {
                              label: item?.title
                                ? item?.title
                                : `option${" "}${index + 1}`,
                            };
                            // return { label: item?.title }
                          })}
                          sx={{ width: 600, height: 50 }}
                          renderInput={(params) => (
                            <TextField
                              style={{ color: "black" }}
                              {...params}
                              className="select_border_width"
                              placeholder={
                                imgquedata.placeholder
                                  ? imgquedata.placeholder
                                  : "Choose an option"
                              }
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>

                  {/* ******************************************************************************ANSWER LAYOUT END***************************** */}
                </Grid>
              </div>

              {imgquedata.image_position === "right" &&
                question_show_iimage.length !== 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={question_show_iimage}
                      width={
                        imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}

              {/* 
              {imgquedata.image_position !== "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {imgquedata.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {imgquedata.subheading}
                  </Typography>
                </>
              )}
              {imgquedata.image_position !== "background" && (
                <div
                  className={
                    imgquedata.image_position === "center" ||
                    imgquedata.image_position === "top"
                      ? "center_position"
                      : imgquedata.image_position === "right"
                      ? "right_position"
                      : "right_imgmain_image"
                  }
                >
                  {question_show_iimage &&
                  question_add_an_iimage?.length !== 0 ? (
                    <div
                      className={
                        imgquedata.image_position === "center" ||
                        imgquedata.image_position === "top"
                          ? "center_position"
                          : imgquedata.image_position === "right"
                          ? "right_position"
                          : "right_imgmain_image"
                      }
                    >
                      <>
                        {imgquedata.image_position == "center" ||
                        imgquedata.image_position === "top" ? (
                          <img
                            src={question_show_iimage}
                            width={`${790 - imgquedata.image_width}px`}
                            height={`${790 - imgquedata.image_width}px`}
                          />
                        ) : (
                          <img
                            src={question_show_iimage}
                            width={`790px`}
                            height={`790px`}
                          />
                        )}
                      </>
                    </div>
                  ) : (
                    <div className=""></div>
                  )}
                </div>
              )}
              {imgquedata.image_position === "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {imgquedata.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {imgquedata.subheading}
                  </Typography>
                </>
              )}

              <div className={"d-flex mt-4 center_small_image_box"}>
                <div
                  className="select_border"
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <Autocomplete
                    autoHighlight
                    id="outlined-size-small"
                    options={imgansdata?.map((item, index) => {
                      return {
                        label: item?.title ? item?.title : `option${index + 1}`,
                      };
                      // return { label: item?.title }
                    })}
                    sx={{ width: 400, height: 50 }}
                    renderInput={(params) => (
                      <TextField
                        style={{ color: "black" }}
                        {...params}
                        className="select_border_width"
                        placeholder={
                          imgquedata.placeholder
                            ? imgquedata.placeholder
                            : "Choose an option"
                        }
                      />
                    )}
                  />
                </div>
              </div> */}

              <div className="info_icon mt-12">
                {imgquedata.tooltip_heading ? (
                  <>
                    <Container sx={{ paddingBottom: 2 }}>
                      <Tooltip
                        arrow
                        sx={{
                          backgroundColor: "transparent",
                          ":hover": "none",
                        }}
                        title={
                          <Grid>
                            <h5 style={{ textAlign: "center" }}>
                              {imgquedata?.tooltip_heading}
                            </h5>
                            <p>{imgquedata?.tooltip_description}</p>
                          </Grid>
                        }
                      >
                        <IconButton>
                          <ReportRoundedIcon
                            sx={{ fontSize: "50px !important" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Container>
                  </>
                ) : (
                  ""
                )}
              </div>

              <Grid container className="" justifyContent="center">
                <Grid
                  className="back"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <WestIcon /> <span className="mx-2"> BACK </span>
                </Grid>
                {imgquedata.skip_que && (
                  <Grid
                    className="back"
                    xs
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span className="mx-2"> SKIP </span>
                  </Grid>
                )}

                <Grid
                  className="next"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <span className="mx-2 "> NEXT </span> <EastIcon />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default RightSelectCard;
