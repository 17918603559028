import React from "react";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { FetchContext } from "../../context/FetchContext";
import { useEffect } from "react";
import Select from "../dropdown/Select";

const LeadtoQuestion = ({
  setimgquedata,
  element,
  index,
  allQuelist,
  imgansdata,
  setimgansdata,
  setIsSaveDisable,
  imgquedata,
}) => {
  const que_type = ["Input", "File upload", "info"];
  function handleAnswerLeadtoChange(e) {
    let newFormValues = [...imgansdata];
    newFormValues[index]["leads_to_id"] = e.target.value;

    setimgansdata(newFormValues);
    setIsSaveDisable(false);
  }
  function handleQuestionLeadtoChange(e) {
    setimgquedata({
      ...imgquedata,
      leads_to_id: e.target.value,
    });
    setIsSaveDisable(false);
  }
  return (
    <>
      <div>
        <span className="dropdown_span">Lead to</span>
        {imgquedata?.multiselect_answer ||
        imgquedata?.skip_que ||
        que_type.includes(imgquedata?.question_types) ? (
          <select
            class="form-select mt-2 drop_box form-select-lg mb-3"
            aria-label=".form-select-lg example"
            //  selected={item === value ? true : false}
            onChange={(e) => handleQuestionLeadtoChange(e)}
          >
            <option value="">Select leads to</option>

            {allQuelist?.map((item, index) => {
              return (
                <option
                  value={item.id}
                  selected={item.id === imgquedata.leads_to_id ? true : false}
                  disabled={item.id === imgquedata?._id ? true : false}
                >
                  {item.label}
                </option>
              );
            })}
            <option
              value={"result_page"}
              selected={"result_page" === imgquedata.leads_to_id ? true : false}
            >
              Result Page
            </option>
          </select>
        ) : (
          <select
            class="form-select mt-2 drop_box form-select-lg mb-3"
            aria-label=".form-select-lg example"
            onChange={(e) => handleAnswerLeadtoChange(e)}
          >
            <option value="">Select leads to</option>
            {allQuelist?.map((item, index) => {
              return (
                <option
                  value={item.id}
                  selected={
                    item.id === element?.answer?.leads_to_id ? true : false
                  }
                  disabled={item.id === element?._id ? true : false}
                >
                  {item.label}
                </option>
              );
            })}
            <option
              value={"result_page"}
              selected={
                "result_page" === element?.answer?.leads_to_id ? true : false
              }
            >
              Result Page
            </option>
          </select>
        )}
      </div>
    </>
  );
};

export default LeadtoQuestion;
