import React, { useContext, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import { ListItemText } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FetchContext } from "../../context/FetchContext";
import { AuthContext } from "../../context/AuthContext";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

const ITEM_HEIGHT = 60;

export default function Cardmenu({ id, is_publish, questions, setLoading }) {
  const { state, dispatch } = useContext(AuthContext);

  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isPublish, setIsPublish] = useState("");
  const handleDeletePopupClose = () => {
    setAnchorEl(null);
    setDeleteModalOpen(false);
  };
  const open = Boolean(anchorEl);

  function handleDeletePopupOpen(e) {
    setDeleteModalOpen(true);
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsPublish("");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsPublish("");
  };

  const handleeditquiz = () => {
    navigate(`/quizzes/intropage/${id}`);
  };
 
  const handlePublish = () => {
    navigate(`/publish/${id}`);
  };
  
  const handledeletequiz = (value) => {
    setLoading(true);
    setAnchorEl(null);
    authAxios
      .delete(`${process.env.REACT_APP_API_URL}/quiz/delete_quiz/${id}`)
      .then((res) => {
        getallquiz();
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          setLoading(false);
        }
      });
    setDeleteModalOpen(false);
  };

  const getallquiz = () => {
    setLoading(true);
    authAxios
      .get(`${process.env.REACT_APP_API_URL}/quiz/all_quiz`)
      .then((res) => {
        const { data } = res;
        dispatch({
          type: "ALL_QUIZ",
          payload: {
            allquiz: data.data,
          },
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          setLoading(false);
        }
      });
  };

  return (
    <>
      <div>
        <Modal
          open={deleteModalOpen}
          onClose={handleDeletePopupClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="main_modal_div">
            <div
              id="modal-modal-title"
              className="product_modal_header py-0 px-2"
            >
              <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                Delete
              </Typography>
              <Typography>
                <IconButton
                  sx={{ padding: "0px" }}
                  onClick={handleDeletePopupClose}
                >
                  <CloseIcon />
                </IconButton>
              </Typography>
            </div>
            <Box className="">
              <div className="product_modal_body">
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, padding: "5px 10px" }}
                >
                  Are you sure want to delete
                </Typography>
              </div>
            </Box>
            <div
              className="product_modal_footer"
              style={{
                padding: "10px 10px",

                gap: "15px",
              }}
            >
              <Button
                onClick={handleDeletePopupClose}
                className="delete_modal_no"
                variant="contained"
              >
                No
              </Button>
              <Button
                onClick={(e) =>
                  isPublish == ""
                    ? handledeletequiz("value")
                    : handlePublish(isPublish)
                }
                className="product_modal_save"
                variant="contained"
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <IconButton
          sx={{ padding: "0px !important" }}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          className="mui_icon_menu_btn"
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          <Grid>
            <MenuItem className="mx-0 " onClick={() => handleeditquiz()}>
              <EditIcon className="icons" />{" "}
              <ListItemText className="m-2 mx-3"> Edit </ListItemText>
            </MenuItem>

            {is_publish ? (
              <MenuItem className="mx-0 " onClick={(e) => handlePublish()}>
                <PublicRoundedIcon className="icons" />{" "}
                <ListItemText className="m-2 mx-3"> Unpublish </ListItemText>
              </MenuItem>
            ) : (
              questions.length !== 0 && (
                <MenuItem className={`mx-0`} onClick={(e) => handlePublish()}>
                  <PublicRoundedIcon className="icons" />{" "}
                  <ListItemText className="m-2 mx-3"> Publish </ListItemText>
                </MenuItem>
              )
            )}
            <MenuItem
              className="mx-0 "
              onClick={(e) => handleDeletePopupOpen(e)}
            >
              <DeleteIcon className="icons" />{" "}
              <ListItemText className="m-2 mx-3"> Delete quiz </ListItemText>
            </MenuItem>
          </Grid>
        </Menu>
      </div>
    </>
  );
}
