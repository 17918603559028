import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CButton } from "@coreui/react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "./accordianradio.css";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import InputComponent from "../inputfiled/Input_type_text";
import DeleteIcon from "@mui/icons-material/Delete";
import Inputtypefile from "../input_file/Input_type_file";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import { CheckBox } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { FetchContext } from "../../context/FetchContext";
import defaultImage from "../../public/default-fe.png";
import Selectproduct from "./Selectproduct";
import LeadtoQuestion from "./LeadtoQuestion";
import SelectproductSingle from "../common/selectproduct/SelectProductSingle";
import SelectResultWeight from "../common/selectproduct/SelectResultWeight";

export default function Accordianimage({
  imgansdata,
  setimgansdata,
  setanswer_show_image,
  answer_add_image,
  setanswer_add_image,
  answer_change_icon,
  setanswer_change_icon,
  answer_show_image,
  validation,
  setIsSaveDisable,
  ProductData,
  resultLogic,
  loadingScrollableApi,
  hasMore,
  getProduct,
  allquestion,
  imgquedata,
  allQuelist,
  setResultData,
  resultData,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [clickurl, setclickurl] = React.useState([]);

  const handleClick = (event, index) => {
    let newFormValues = [...imgansdata];
    const _clicked = [...clickurl];
    _clicked[index] = event.target.checked;
    setclickurl(_clicked);
    setIsSaveDisable(false);
    if (event.target.checked) {
      newFormValues[index].url_valid = true;
    } else {
      newFormValues[index].url_valid = false;
    }
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const validateURL = (input) => {
    if (input) {
      const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-zA-Z\\d_]*)?$",
        "i" // fragment locator
      );
      return urlPattern.test(input);
    } else {
      return true;
    }
  };
  let handleInputChange = (i, e) => {
    let newFormValues = [...imgansdata];
    if (e.target.name === "title" && e.target.value === "") {
      newFormValues[i].valid = true;
    } else if (e.target.name === "product_point" && e.target.value < 0) {
      newFormValues[i].valid = true;
    } else {
      if (e.target.name === "description" && imgansdata[i].title === "") {
        newFormValues[i].valid = true;
      } else {
        newFormValues[i].valid = false;
      }
    }

    if (clickurl.length === 0) {
      newFormValues[i].url_valid = false;
    }
    newFormValues[i][e.target.name] = e.target.value;

    setimgansdata(newFormValues);
    setIsSaveDisable(false);
  };
  let handleUrlChange = (i, e) => {
    let newFormValues = [...imgansdata];

    if (!validateURL(e.target.value) || e.target.value === "") {
      newFormValues[i].url_valid = true;
    } else {
      newFormValues[i].url_valid = false;
      if (newFormValues[i].title === "") {
        newFormValues[i].valid = true;
      }
    }
    newFormValues[i][e.target.name] = e.target.value;

    setimgansdata(newFormValues);
    setIsSaveDisable(false);
  };
  let handleImageChange = (e, index) => {
    setIsSaveDisable(false);
    setanswer_show_image({
      ...answer_show_image,
      [index]: URL.createObjectURL(e.target.files[0]),
    });
    const files = e.target.files[0];
    let newFormValues = [...imgansdata];
    delete newFormValues[index]["updated_Img"];
    setimgansdata(newFormValues);
    // new code
    setanswer_add_image((prevState) => {
      const updatedArray = [...prevState];
      while (updatedArray.length <= index) {
        updatedArray.push(null);
      }
      updatedArray[index] = files;

      return updatedArray;
    });
  };

  let addFormFields = () => {
    setIsSaveDisable(false);
    setimgansdata([
      ...imgansdata,
      {
        title: "",
        description: "",
        select_products: [],
        product_point: "",
        leads_to_id: "",
        custom_redirect_url: false,
        url: "",
        updated_Img: "default.png",
        valid: true,
        url_valid: true,
        select_result_id: "",
      },
    ]);
  };
  const handleRemoveImage = async (i) => {
    setIsSaveDisable(false);
    setanswer_show_image({
      ...answer_show_image,
      [i]: "",
    });

    const new_answer_add_image = [...answer_add_image];
    new_answer_add_image.splice(i, 1);

    setanswer_add_image(new_answer_add_image);

    let newFormValues = [...imgansdata];
    newFormValues[i]["updated_Img"] = "default.png";
    setimgansdata(newFormValues);
  };
  let removeFormFields = (i) => {
    setIsSaveDisable(false);
    let newFormValues = [...imgansdata];
    newFormValues.splice(i, 1);
    setimgansdata(newFormValues);
  };
  let handleSubmit = (event) => {
    setIsSaveDisable(false);
    event.preventDefault();
    alert(JSON.stringify(imgansdata));
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {imgansdata?.map((element, index) => {
          return (
            <Accordion
              key={index}
              className="acordian_answer"
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="accordian-option"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className=""
                >
                  <Grid item xs="auto" className="">
                    <DragIndicatorIcon />
                  </Grid>
                  <Grid item xs="auto" className="radio_answer_option_label">
                    {element.title ? element.title : "option"}
                  </Grid>
                  <Grid item xs="auto" className="down_arrow"></Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <div className="" style={{ marginTop: "-10px" }}>
                  <InputComponent
                    placeholder={"Option 1"}
                    value={element.title || ""}
                    label_name={"Title"}
                    name={"title"}
                    onChange={(e) => {
                      handleInputChange(index, e);
                    }}
                  />
                </div>
                {element.valid || validation ? (
                  <p className="text-[red] mx-2 mt-[-8px] text-sm">
                    {" "}
                    Title can't be blank{" "}
                  </p>
                ) : (
                  ""
                )}
                <div className="" style={{ marginTop: "-10px" }}>
                  <InputComponent
                    placeholder={"Add detail about this option (optional)"}
                    name={"description"}
                    value={element.description || ""}
                    label_name={"Description"}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
                <div className="mt-4">
                  <span> image</span>
                </div>
                <Grid className="d-flex mt-2 gap-3 " xs={12}>
                  {answer_show_image[index] === undefined ||
                  answer_show_image[index] === "" ? (
                    <>
                      <div className="mt-1 w-full">
                        <Inputtypefile
                          name={answer_add_image}
                          image_text="Add an image"
                          onChange={(e) => {
                            handleImageChange(e, index);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <Grid className="d-flex mt-1" xs={12} container>
                        <Grid item className="pl-0 " xs={8}>
                          <Inputtypefile
                            image_text="Change an image"
                            onChange={(e) => {
                              handleImageChange(e, index);
                            }}
                          />
                        </Grid>
                        <Grid item className="pl-0 px-2" xs={4}>
                          <CButton
                            className="preview remove"
                            onClick={(e) => {
                              handleRemoveImage(index);
                            }}
                          >
                            {" "}
                            Remove
                          </CButton>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                {allquestion.quiz?.logic_jump &&
                  !imgquedata.multiselect_answer && (
                    <div className="mt-4">
                      <LeadtoQuestion
                        imgquedata={imgquedata}
                        element={element}
                        index={index}
                        allQuelist={allQuelist}
                        setIsSaveDisable={setIsSaveDisable}
                        imgansdata={imgansdata}
                        setimgansdata={setimgansdata}
                      />
                    </div>
                  )}

                {resultLogic === "Points" && (
                  <div>
                    <InputComponent
                      placeholder={"Enter Point"}
                      value={element.product_point || ""}
                      label_name={"Product Point"}
                      name={"product_point"}
                      min={"0"}
                      onChange={(e) => {
                        handleInputChange(index, e);
                      }}
                      type="number"
                    />
                  </div>
                )}

                {resultLogic === "Single" && (
                  <div className="select_product_componant mt-4">
                    <SelectproductSingle
                      setimgansdata={setimgansdata}
                      imgansdata={imgansdata}
                      ProductData={ProductData}
                      element={element}
                      index={index}
                      setIsSaveDisable={setIsSaveDisable}
                      loadingScrollableApi={loadingScrollableApi}
                      hasMore={hasMore}
                      getProduct={getProduct}
                      resultLogic={resultLogic}
                    />
                  </div>
                )}

                {resultLogic === "Product Weight" && (
                  <div className="select_product_componant mt-4">
                    <Selectproduct
                      setimgansdata={setimgansdata}
                      imgansdata={imgansdata}
                      ProductData={ProductData}
                      element={element}
                      index={index}
                      setIsSaveDisable={setIsSaveDisable}
                      loadingScrollableApi={loadingScrollableApi}
                      hasMore={hasMore}
                      getProduct={getProduct}
                      resultLogic={resultLogic}
                    />
                  </div>
                )}

                {resultLogic === "Result Weight" && (
                  <div className="select_product_componant mt-4">
                    <SelectResultWeight
                      setimgansdata={setimgansdata}
                      imgansdata={imgansdata}
                      resultData={resultData}
                      element={element}
                      index={index}
                      setIsSaveDisable={setIsSaveDisable}
                      loadingScrollableApi={loadingScrollableApi}
                      hasMore={hasMore}
                      getProduct={getProduct}
                      resultLogic={resultLogic}
                    />
                  </div>
                )}

                <div className="d-flex mt-4">
                  <Checkbox
                    name="custom_redirect_url"
                    className="p-0"
                    onClick={(e) => handleClick(e, index)}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    onChange={(e, val) => {
                      let newFormValues = [...imgansdata];
                      newFormValues[index][e.target.name] = val;
                      setimgansdata(newFormValues);
                    }}
                  ></Checkbox>
                  <Typography
                    color="text.secondary"
                    className=" mx-2 logic_jump"
                  >
                    Custom redirect URL
                  </Typography>
                </div>
                <div className="mx-6 ml-8 opacity-70">
                  <Typography
                    color="text.secondary"
                    className="mt-1"
                    style={{ letterSpacing: "1px" }}
                  >
                    if a user chooses this option,skip the <br /> Results page
                    and instead send them to <br /> custom URL
                  </Typography>
                </div>
                <div
                  className={
                    (clickurl.length > index || !clickurl[index] === "") &&
                    clickurl[index] === true
                      ? "custom_url_clicked"
                      : "mx-2 custom_url"
                  }
                  style={{ marginTop: "-15px" }}
                >
                  <InputComponent
                    placeholder={"URL"}
                    name={"url"}
                    onChange={(e) => handleUrlChange(index, e)}
                  />
                  {element.url_valid || validation ? (
                    <p className="text-[red] mx-2 mt-[-8px] text-sm">
                      {" "}
                      please enter valid url{" "}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                {imgansdata.length > 1 && (
                  <div className="col-12 main_optdelete  text-end">
                    <Typography sx={{ fontSize: 14 }} gutterBottom>
                      <Button
                        className="delete_option_btn"
                        size="lg"
                        onClick={() => {
                          removeFormFields(index);
                        }}
                      >
                        <DeleteIcon className="" />
                        Delete option
                      </Button>
                    </Typography>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
        <CButton
          size="lg"
          className="button_component w-100 "
          style={{ marginTop: "30px" }}
          onClick={() => addFormFields()}
        >
          Add option
        </CButton>
      </form>
    </div>
  );
}
