import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    IconButton,
    Modal,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import defaultImage from "../..//..//public/default-fe.png";


import React, { useState, useEffect } from "react";

const SelectproductSingle = ({
    isEdit,
    imgansdata,
    setimgansdata,
    ProductData,
    element,
    index,
    resultProduct,
    setResultProduct,
    setIsSaveDisable,
    hasMore,
    getProduct,
    loadingScrollableApi,
    resultLogic
}) => {

    // const result_option = element?.answer?.select_products ? element?.answer?.select_products : resultProduct
    let option_product = []
    if (resultProduct) {
        option_product = resultProduct
    } else {
        option_product = element?.answer?.select_products ?? [];
    }

    const [productchecked, setProductChecked] = useState([]);
    const [selectedProductIds, setSelectedProductIds] = useState(option_product);
    // setResultProduct(selectedProductIds)
    const [intermediateArray, setIntermediateArray] = useState([]);
    const [productopen, setProductOpen] = useState(false);

    const handleProductPopupClose = () => {
        setProductOpen(false)
        if (resultProduct) {
            setResultProduct(selectedProductIds)
        }
    };

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight + 1 && hasMore) {
            if (getProduct) {
                getProduct()
            }
        }
    };

    useEffect(() => {
        setSelectedProductIds(option_product);
    }, [resultProduct, element]);

    function handleProductRemove(product_index, product) {

        const targetProductGid = product.product.product_gid;
        const remove_index = ProductData.findIndex(
            (product) => product.product_gid === targetProductGid
        );
        if (remove_index !== -1) {
            productchecked[remove_index].parent = false;
            productchecked[remove_index].variants = productchecked[
                remove_index
            ].variants.map(() => false);
            // intermediateArray = intermediateArray.filter(item => item.id !== remove_index)
            setIntermediateArray((prevState) =>
                prevState.filter((item) => item.id !== remove_index)
            );
        }
        setSelectedProductIds((prevSelectedIds) => [
            ...prevSelectedIds.slice(0, product_index),
            ...prevSelectedIds.slice(product_index + 1),
        ]);


        const newWeights = [...selectedProductIds];
        let newFormValues = [...imgansdata];
         newWeights.splice(product_index, 1);
        if (isEdit) {
          newFormValues[index].answer.select_products = newWeights;
        } else {
          newFormValues[index].select_products = newWeights;
        }
        setSelectedProductIds(newWeights)
        setimgansdata(newFormValues);


        if (resultProduct) {
            setResultProduct(((prevSelectedIds) => [
                ...prevSelectedIds.slice(0, product_index),
                ...prevSelectedIds.slice(product_index + 1),
            ]));
        }
        setIsSaveDisable(false);
        productchecked[product.product_index].parent = false;
        // Set all elements in the variants array to false
        productchecked[product.product_index].variants = productchecked[
            product.product_index
        ].variants.map(() => false);
    }


    const handleProductSelect = (event, product_index, product_item, index) => {
        setIsSaveDisable(false);
        const newChecked = [...productchecked];
        newChecked[product_index].parent = event.target.checked;
        newChecked[product_index].variants = newChecked[product_index].variants.map(
            () => event.target.checked
        );
        setProductChecked(newChecked);

        const newSelectedIds = [...selectedProductIds];
        if (event.target.checked) {
            newSelectedIds.push({
                // product: ProductData[index],
                product: {
                    product_gid: product_item.product_gid,
                    product_id: product_item.product_id,
                    title: product_item.title,
                    image_url: product_item.image_url,
                },
                variants: product_item.product_json.variants.map((v) => {
                    return {
                        id: v.id,
                        __parentId: v.__parentId,
                        title: v.title,
                        variant_weight: 0,
                        variant_url: v.image?.url ?? null,
                    };
                }),
                // product_index: index,
            });
        } else {
            const productIndex = newSelectedIds.findIndex(
                (p) => p.product.product_gid === product_item.product_gid
            );
            if (productIndex !== -1) {
                newSelectedIds.splice(productIndex, 1);
            }
        }
        if (resultProduct) {
            setResultProduct(newSelectedIds);
        }
        let newFormValues = [...imgansdata];

        if (isEdit) {
            newFormValues[index].answer.select_products = newSelectedIds;
        } else {
            newFormValues[index].select_products = newSelectedIds;
        }
        setimgansdata(newFormValues);

        setSelectedProductIds(newSelectedIds);
    };

    const handleVariantSelect = (
        event,
        productIndex,
        variantIndex,
        variant_item,
        product_item
    ) => {
        setIsSaveDisable(false);
        const newChecked = [...productchecked];
        newChecked[productIndex].variants[variantIndex] = event.target.checked;
        newChecked[productIndex].parent = newChecked[productIndex].variants.every(
            (isChecked) => isChecked
        );
        setProductChecked(newChecked);

        const newSelectedIds = [...selectedProductIds]; // Copy selectedIds array

        if (event.target.checked) {
            // Add productId to product array if not already present
            if (
                !newSelectedIds.some(
                    (item) => item.product.product_gid === product_item.product_gid
                )
            ) {
                newSelectedIds.push({
                    product: {
                        product_gid: product_item.product_gid,
                        product_id: product_item.product_id,
                        title: product_item.title,
                        image_url: product_item.image_url,
                    },
                    variants: [],
                });
            }
            // Add variantId to variants array
            newSelectedIds.forEach((item) => {
                if (
                    item.product.product_gid === product_item.product_gid &&
                    !item.variants.includes(variant_item.id)
                ) {
                    item.variants.push({
                        id: variant_item.id,
                        __parentId: variant_item.__parentId,
                        variant_weight: 0,
                        title: variant_item.title,
                        variant_url: variant_item?.image?.url,
                    });
                }
            });
        } else {
            // Remove variantId from variants array
            newSelectedIds.forEach((item) => {
                if (item.product.product_gid === product_item.product_gid) {
                    item.variants = item.variants.filter(
                        (variantObj) => variantObj.id !== variant_item.id
                    );
                }
            });
            // Remove productId if no more variants selected and condition is met
            if (!newChecked[productIndex].parent) {
                newSelectedIds.forEach((item, index) => {
                    if (
                        item.product.product_gid === product_item.product_gid &&
                        item.variants.length === 0
                    ) {
                        newSelectedIds.splice(index, 1);
                    }
                });
            }
        }
        setSelectedProductIds(newSelectedIds);

        // Update intermediate_array based on the checked status
        const newIntermediateArray = [...intermediateArray];
        if (event.target.checked) {
            const newEntry = {
                id: productIndex,
                status: [true],
            };
            newIntermediateArray.push(newEntry);
        } else {
            const index = newIntermediateArray.findIndex(
                (item) => item.id === productIndex
            );
            if (index !== -1) {
                newIntermediateArray.splice(index, 1);
            }
        }
        setIntermediateArray(newIntermediateArray);
    };


    function handleProductPopupOpen(e) {
        setProductOpen(true);
    }
    useEffect(() => {
        if (ProductData?.length > 0) {
            setProductChecked(
                ProductData.map((item, productIndex) => {
                    let matchedProduct = selectedProductIds?.find(
                        (i) => i.product?.product_gid === item.product_gid
                    );
                    if (matchedProduct) {
                        const newIntermediateArray = [...intermediateArray];
                        const newEntry = {
                            id: productIndex,
                            status: [true],
                        };
                        newIntermediateArray.push(newEntry);
                        setIntermediateArray(newIntermediateArray);
                        return {
                            parent:
                                matchedProduct.variants?.length ===
                                    item.product_json.variants?.length
                                    ? true
                                    : false,
                            variants: item.product_json.variants.map((variant_item) => {
                                if (
                                    matchedProduct.variants?.find(
                                        (id) => id.id === variant_item.id
                                    )
                                ) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }),
                        };
                    } else {
                        return {
                            parent: false,
                            variants: item.product_json.variants.map(() => false),
                        };
                    }
                })
            );
        }
    }, [ProductData]);

 

    return (
        <>
            <div className="mt-1 w-full">
                <Button
                    className="add_product_btn"
                    variant="outlined"
                    fullWidth
                    onClick={(e) => handleProductPopupOpen(e)}
                    sx={{
                        textTransform: "none",
                        color: "black",
                        border: "1px solid #B1B7C1",
                        fontWeight: "bold",
                        fontSize: "18px",
                    }}
                >
                    {selectedProductIds?.length ? "Upadate product" : "Add Product"}
                </Button>
                <div>
                    <Modal
                        open={productopen}
                        onClose={handleProductPopupClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className="main_modal_div">
                            <Typography
                                id="modal-modal-title"
                                className="product_modal_header"
                            >
                                <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                                    {selectedProductIds?.length
                                        ? "Upadate product"
                                        : "Add Product"}
                                </Typography>
                                <Typography>
                                    <IconButton
                                        sx={{ padding: "0px" }}
                                        onClick={handleProductPopupClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Typography>
                            </Typography>
                            <Box className="product_modal_box" onScroll={handleScroll}
                                style={{ overflow: loadingScrollableApi && "hidden" }}>
                                <div className="product_modal_body">
                                    <Typography
                                        id="modal-modal-description"
                                        sx={{ mt: 2, padding: " 10px 20px" }}
                                    >
                                        Duis mollis, est non commodo luctus, nisi erat porttitor
                                        ligula.
                                    </Typography>
                                    <div
                                        style={{
                                            padding: "10px 0px",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "start",
                                            alignItems: "start",
                                        }}
                                    >
                                        {!ProductData && <CircularProgress color="inherit" />}
                                        {ProductData && (
                                            <>
                                                <Divider sx={{ width: "100%", borderColor: "black" }} />
                                                {ProductData?.map((item, productdata_index) => {
                                                    const variant_list = item.product_json?.variants;
                                                    let matchFound = intermediateArray.some(
                                                        (obj) => obj.id === productdata_index
                                                    );

                                                    return (
                                                        <>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    sx={{ margin: "0px 10px" }}
                                                                    control={
                                                                        <>
                                                                            <Checkbox
                                                                                checked={
                                                                                    productchecked[productdata_index]
                                                                                        ?.parent || false
                                                                                }
                                                                                indeterminate={
                                                                                    productchecked[productdata_index]
                                                                                        ?.parent === false && matchFound
                                                                                        ? matchFound
                                                                                        : false
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleProductSelect(
                                                                                        event,
                                                                                        productdata_index,
                                                                                        item,
                                                                                        index
                                                                                    )
                                                                                }
                                                                            />
                                                                            <div
                                                                                style={{
                                                                                    marginTop: "5px",
                                                                                    marginRight: "10px",
                                                                                    border: "1px solid #B1B7C1",
                                                                                    borderRadius: "10px",
                                                                                    padding: "2px",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={item.image_url ?? defaultImage}
                                                                                    alt={item.title}
                                                                                    height={40}
                                                                                    width={40}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    }
                                                                // label={item.title}
                                                                />
                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                    {item.title}
                                                                </Typography>
                                                            </div>
                                                            <div>
                                                                {variant_list.map(
                                                                    (variant_item, variant_index) => {
                                                                        return (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <FormControlLabel
                                                                                    sx={{ marginLeft: "80px" }}
                                                                                    control={
                                                                                        <>
                                                                                            <Checkbox
                                                                                                checked={
                                                                                                    productchecked[
                                                                                                        productdata_index
                                                                                                    ]?.variants[variant_index] ||
                                                                                                    false
                                                                                                }
                                                                                                onChange={(event) =>
                                                                                                    handleVariantSelect(
                                                                                                        event,
                                                                                                        productdata_index,
                                                                                                        variant_index,
                                                                                                        variant_item,
                                                                                                        item
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                            <div>
                                                                                                <div
                                                                                                    style={{
                                                                                                        marginTop: "5px",
                                                                                                        marginRight: "10px",
                                                                                                        border: "1px solid #B1B7C1",
                                                                                                        borderRadius: "10px",
                                                                                                        padding: "2px",
                                                                                                        margin: "5px 0px",
                                                                                                    }}
                                                                                                >
                                                                                                    <img
                                                                                                        src={
                                                                                                            variant_item.image?.url ??
                                                                                                            defaultImage
                                                                                                        }
                                                                                                        alt={variant_item.title}
                                                                                                        height={40}
                                                                                                        width={40}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                />
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    {variant_item.title ===
                                                                                        "Default Title"
                                                                                        ? item.title
                                                                                        : variant_item.title}
                                                                                </Typography>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                            <Divider
                                                                sx={{
                                                                    width: "100%",
                                                                    borderColor: "black",
                                                                }}
                                                            />
                                                        </>
                                                    );
                                                })}
                                            </>
                                        )}
                                        <div style={{ marginLeft: "45%", marginTop: "3%", marginBottom: "6%" }}>
                                            {loadingScrollableApi
                                                &&
                                                <CircularProgress color="inherit" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Box>
                            <div
                                className="product_modal_footer"
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    padding: "10px 10px",
                                    alignItems: "center",
                                    borderTop: "1px solid #B1B7C1",
                                }}
                            >
                                <Button
                                    onClick={handleProductPopupClose}
                                    className="product_modal_save"
                                    variant="contained"
                                >
                                    Save and Close
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>


            <div>
                {selectedProductIds?.lenght !== 0 && (
                    <div>
                        {selectedProductIds?.length !== 0 && (
                            <Typography
                                color="text.prymary"
                                className="mt-3"
                                sx={{ fontSize: "18px", fontWeight: "semibold" }}
                            >
                                Selected products
                            </Typography>
                        )}
                        {selectedProductIds.map((select_product, select_index) => {
                            return (
                                <div
                                    style={{
                                        backgroundColor: "#F6F6F7",
                                        padding: "5px 15px",
                                        borderRadius: "7px",
                                        marginTop: "10px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <p
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    margin: "0px",
                                                }}
                                            >
                                                {select_index + 1}.
                                            </p>

                                            <img
                                                src={select_product.product.image_url ?? defaultImage}
                                                alt={select_product.product.title}
                                                height={40}
                                                width={40}
                                            />
                                            <p
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    margin: "0px",
                                                }}
                                            >
                                                {select_product.product.title}
                                            </p>
                                        </div>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() =>
                                                handleProductRemove(select_index, select_product)
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

        </>
    );
};

export default SelectproductSingle;
