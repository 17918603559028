import React, { useContext, useEffect } from "react";
import { Backdrop, Button, Card, CircularProgress, Container, Grid, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FetchContext } from "../../context/FetchContext";
import { AuthContext } from "../../context/AuthContext";
import CloseIcon from "@mui/icons-material/Close";

const PreviewEnableIntroPage = () => {
  const { authAxios } = useContext(FetchContext);
  const { state, dispatch } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  
  useEffect(() => {
    authAxios
      .post(`${process.env.REACT_APP_API_URL}/question/show_question/${id}`)
      .then((res) => {
        const { data } = res;
        dispatch({
          type: "QUESTION",
          payload: {
            question: data,
          },
        });
        navigate(`/question/${state?.question?.result[0]?._id}/0`);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }, [id]);

  return (
    <>
      <div sx={{ display: "flex" }}>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit"  />
        </Backdrop>
      </div>
    </>
  );
};

export default PreviewEnableIntroPage;

