import React, { useContext } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import HomeIcon from "@mui/icons-material/Home";
import MoneyIcon from "@mui/icons-material/Money";
import QuizIcon from "@mui/icons-material/Quiz";
import LogoutIcon from "@mui/icons-material/Logout";
import EventIcon from "@mui/icons-material/Event";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import AddchartIcon from "@mui/icons-material/Addchart";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor: "white",
      color: "black",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Sidenav(props) {
  const { window } = props;
  const { state, dispatch } = useContext(AuthContext);

  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handlecloser = () => {
    setMobileOpen(true);
  };
  function backShopify() {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  }
  function logout() {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  }
  const icon1 = [
    <MoneyIcon className="icon1" />,
    <LogoutIcon className="icon1" />,
  ];
  const icons = [
    <HomeIcon className="icons" />,
    <QuizIcon className="icons" />,
    <SignalCellularAltOutlinedIcon className="icons" />,
    <EventIcon className="icons" />,
    <AddchartIcon className="icons" />,
    <RequestQuoteIcon className="icons" />,
  ];
  const location = [
    "/dashboard",
    "/quizzes/allquiz",
    "/Analytic/analytic",
    "/integration",
    "/billing",
  ];
  const location1 = ["/Help_center", "/Contact_us"];
  const drawer = (
    <div>
      {state.loginadmin.shop ? (
        <Grid className="main_header" onClick={backShopify}>
          <KeyboardBackspaceIcon className="mr-3 m-4" />
          <span className="mr-5"> Back to Shopify </span>
        </Grid>
      ) : (
        <Grid className="main_header" onClick={logout}>
          <LogoutIcon className="mr-3 m-4" />
          <span className="mr-5"> Log Out </span>
        </Grid>
      )}
      <Divider />
      <Grid className="m-4"> Navigation</Grid>
      <List>
        {["Dashboard", "Quizzes", "Analytic", "Integration", "Billing"].map(
          (text, index) => (
            <Link className="text-decoration-none" to={location[index]}>
              <ListItem className="sidenav_menu" button key={text}>
                <ListItemIcon>{icons[index]}</ListItemIcon>
                <ListItemText className="linkname" primary={text} />
              </ListItem>
            </Link>
          )
        )}
      </List>
      <Divider />
      <Grid className="m-4">Help</Grid>
      <List>
        {[" Help center", "Contact us"].map((text, index) => (
          <Link className="text-decoration-none" to={location1[index]}>
            <ListItem className="sidenav_menu" button key={text}>
              <ListItemIcon>{icon1[index]}</ListItemIcon>
              <ListItemText className="linkname" primary={text} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}

Sidenav.propTypes = {
  window: PropTypes.func,
};

export default Sidenav;
