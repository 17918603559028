import { Button, Card, Container, Grid, TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import { FetchContext } from "../../context/FetchContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../context/AuthContext";
import CloseIcon from "@mui/icons-material/Close";

const Subscribe = ({ redirectURL }) => {
  const { authAxios } = useContext(FetchContext);
  const {
    state: {
      question: { quiz },
    },
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter a valid email address"),
    }),
    onSubmit: (values) => {
      const value = values.email;
      let valueArr = {};
      if (localStorage.getItem("quizData")) {
        valueArr = JSON.parse(localStorage.getItem("quizData"));
      }
      valueArr[`subscribe`] = value;
      localStorage.setItem("quizData", JSON.stringify(valueArr));
      formik.resetForm();

      const answers = Object.keys(valueArr)
        .filter((key) => key !== "subscribe")
        .map((key) => valueArr[key]);
      const data = {
        user_answers: answers,
        user_subscribe: valueArr.subscribe,
        quiz_id: answers[0].quizId,
        result_logic: answers[0].result_logic,
        shop: quiz.shop,
        is_tested: true,
      };
      authAxios
        .post(
          `${process.env.REACT_APP_API_URL}/submission/create_submission`,
          data
        )
        .then((res) => {
          if (res.data.result) {
            navigate(
              `/quiz/resultlayout/${res.data.result.Createsubmission.quiz_id}/${res.data.result.Createsubmission._id}`
            );
          }
          localStorage.removeItem("quizData");
          localStorage.removeItem("quizLogicJumpData");
          localStorage.removeItem("back_action");
        })
        .catch((err) => {
          console.error(err);
        });
    },
  });

  const handleRedirect = () => {
    let valueArr = {};
    if (localStorage.getItem("quizData")) {
      valueArr = JSON.parse(localStorage.getItem("quizData"));
    }
    const answers = Object.keys(valueArr)
      .filter((key) => key !== "subscribe")
      .map((key) => valueArr[key]);
    const data = {
      user_answers: answers,
      quiz_id: answers[0].quizId,
      result_logic: answers[0].result_logic,
      shop: quiz.shop,
      is_tested: true,
    };
    authAxios
      .post(
        `${process.env.REACT_APP_API_URL}/submission/create_submission`,
        data
      )
      .then((res) => {
        if (res.data.result) {
          navigate(
            `/quiz/resultlayout/${res.data.result.Createsubmission.quiz_id}/${res.data.result.Createsubmission._id}`
          );
        }
        localStorage.removeItem("quizData");
        localStorage.removeItem("quizLogicJumpData");
        localStorage.removeItem("back_action");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const ManageQuestions = () => {
    return (
      <div>
        <Button type="submit" className="custom btn">
          <b>Submit and see result</b>
        </Button>
        <div style={{ marginBlock: "40px", fontWeight: "400" }}>No Thanks </div>
      </div>
    );
  };

  return (
    <>
      <Card>
        <div className="d-flex justify-end p-2 mx-3">
          <Link to="/quizzes/allquiz">
            <CloseIcon
              className="m-2 font-semibold text-black"
              style={{ fontSize: "35px" }}
            />
          </Link>
        </div>
      </Card>
      <Grid className="subscribe" height={"100vh"}>
        <Grid sx={{ width: "100%" }}>
          <Grid>
            <div style={{ paddingBlock: "20px" }}>
              <MailOutlineRoundedIcon fontSize="large" />
            </div>
            <Typography variant="body1" color="inherit">
              ALMOST THERE
            </Typography>
            <Typography mt={3} variant="h4" color="inherit">
              Join our newsletter for exclusive discounts
            </Typography>
          </Grid>
          <div style={{ paddingBlock: "30px" }}>
            <Container>
              <form
                onSubmit={formik.handleSubmit}
                style={{ textAlign: "center" }}
              >
                <div>
                  <TextField
                    type={"email"}
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className="email-input"
                    placeholder="Enter Your Email address"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                  />
                  <div
                    className="text-danger mt-3"
                    style={{ fontSize: "20px" }}
                  >
                    {formik.errors.email}
                  </div>
                </div>
                <div style={{ marginTop: "30px" }}>
                  <Button type="submit" className="custom btn">
                    <b>Submit and see result</b>
                  </Button>
                  <br />
                  <Button
                    variant="text"
                    className="text-dark mt-3"
                    style={{ backgroundColor: "#F6F6F7" }}
                    onClick={() => handleRedirect()}
                  >
                    {" "}
                    No Thanks
                  </Button>
                </div>
              </form>
            </Container>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Subscribe;
