import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";

const InfoQuestion = ({
  data,
  questionNumber,
  redirectPrevURL,
  redirectURL,
  topText,
  isBack,
  result_json_data,
  setQuiz_logic_jump_data,
  quiz,
  result,
}) => {
  console.log("data", data);
  const [value, setValue] = useState("380");
  const [active, setActive] = React.useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setQuiz_logic_jump_data((prevData) => {
      const newData = [...prevData];
      newData[questionNumber] = data.leads_to_id;
      return newData;
    });
  }, [data]);
  const handleRedirect = (dir) => {
    if (localStorage.getItem("quizData")) {
      var valueArr = JSON.parse(localStorage.getItem("quizData"));
    } else {
      var valueArr = {};
    }
    valueArr[`${questionNumber}`] = {
      questionId: data._id,
      answerId: null,
      answerIds: null,
      resultWeightIds: null,
      result_logic: result_json_data.result_logic,
      product_weight: null,
      product_point: null,
      quizId: data.quiz_id,
    };
    localStorage.setItem("quizData", JSON.stringify(valueArr));
    setValue("");
    if (dir) {
      if (data.multiselect_answer === true) {
        let backActionArr = localStorage.getItem("back_action")
          ? JSON.parse(localStorage.getItem("back_action"))
          : [];

        if (data.leads_to_id) {
          backActionArr.push(data.leads_to_id);
        } else {
          if (result.length - 1 > questionNumber) {
            backActionArr.push(result[questionNumber + 1]?._id);
          }
        }

        localStorage.setItem("back_action", JSON.stringify(backActionArr));
      } else {
        let backActionArr = localStorage.getItem("back_action")
          ? JSON.parse(localStorage.getItem("back_action"))
          : [];

        if (result.length - 1 > questionNumber) {
          backActionArr.push(result[questionNumber + 1]?._id);
        }

        localStorage.setItem("back_action", JSON.stringify(backActionArr));
      }
      navigate(redirectURL);
    } else {
      if (quiz.logic_jump === true) {
        const back_action_arr = JSON.parse(localStorage.getItem("back_action"));
        const question_id = back_action_arr.pop();
        const secondLastId =
          back_action_arr.length !== 0
            ? back_action_arr[back_action_arr.length - 1]
            : result[0]?._id;
        localStorage.setItem("back_action", JSON.stringify(back_action_arr));
        const prevQuestionIndex = result.findIndex(
          (question) => question._id === secondLastId
        );
        const prevQuestionObject = result.find(
          (question) => question._id === secondLastId
        );

        navigate(`/question/${prevQuestionObject?._id}/${prevQuestionIndex}`);
      } else {
        navigate(redirectPrevURL);
      }
    }
  };

  const ManageQuestions = () => {
    return (
      <>
        {data.placeholder.length === 0 && (
          <>
            <Grid container justifyContent="center" mb={2}>
              {isBack ? (
                <Grid item xs>
                  <Button
                    onClick={() => handleRedirect(false)}
                    className="btn-secondary"
                    startIcon={<ArrowBackIcon />}
                  >
                    <b>Back</b>
                  </Button>
                </Grid>
              ) : null}
              {value.length === 0 ? (
                <Grid item xs>
                  <Button disabled endIcon={<ArrowForwardIcon />}>
                    <b>Next</b>
                  </Button>
                </Grid>
              ) : (
                <Grid item xs>
                  <Button
                    onClick={() => handleRedirect(true)}
                    className="btn-secondary"
                    endIcon={<ArrowForwardIcon />}
                  >
                    <b>Next</b>
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </>
    );
  };
  const TooltipComponent = () => {
    return (
      <>
        {data.tooltip_heading !== null && (
          <Container sx={{ paddingBottom: 10 }}>
            <Tooltip
              arrow
              sx={{ backgroundColor: "#ffffff" }}
              title={
                <Grid>
                  <h6 style={{ textAlign: "center" }}>
                    {data.tooltip_heading}
                  </h6>
                  <p>{data.tooltip_description}</p>
                </Grid>
              }
            >
              <IconButton>
                <ReportRoundedIcon sx={{ height: 50, width: 50 }} />
              </IconButton>
            </Tooltip>
          </Container>
        )}
      </>
    );
  };
  const AnswerLayout = () => {
    return (
      <Container>
        {data.placeholder.length !== 0 && (
          <Grid>
            {/* {data.button_title && <Button className='custom btn' title={data.button_title}/>} */}
            <Button
              className="custom btn"
              onClick={() => navigate(redirectURL)}
              title={""}
            >
              {data.placeholder}
            </Button>
          </Grid>
        )}
      </Container>
    );
  };
  const LeftStructure = () => {
    return (
      <Grid container justifyContent="center" sx={{ height: "100vh" }}>
        <Grid item xs={12} md={6} sm={12}>
          <div className="structure-fill img">
            <img src={data.question_add_an_iimage} alt="" srcset="" />
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
          <Grid
            className="radioContainer content_tab_info"
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "0px !important",
            }}
          >
            <Grid style={{ width: "100%" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <div style={{ paddingBlock: "20px" }}>
                <AnswerLayout />
              </div>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const RightStructure = () => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
          <Grid
            className="radioContainer content_tab_info"
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "0px !important",
              height: "100vh",
            }}
          >
            <Grid style={{ width: "100%" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <div style={{ paddingBlock: "20px" }}>
                <AnswerLayout />
              </div>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <div className="structure-fill img">
            <img src={data?.question_add_an_iimage} alt="" srcset="" />
          </div>
        </Grid>
      </Grid>
    );
  };
  const CenterStructure = () => {
    return (
      <Grid
        className="radioContainer"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Grid sx={{ width: "100%" }}>
          <Grid>
            <Typography variant="body1" color="inherit">
              {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
            </Typography>
            <Typography mt={3} variant="h4" color="inherit">
              {data.heading}
            </Typography>
            <Typography mt={3} variant="h6" color="inherit">
              {data.subheading}
            </Typography>
            {data?.question_add_an_iimage && (
              <div className="d-flex justify-center mt-3">
                <img
                  src={data?.question_add_an_iimage}
                  height={790 - parseInt(data.image_width)}
                  width={790 - parseInt(data.image_width)}
                  alt=""
                  srcset=""
                />
              </div>
            )}
          </Grid>
          <div style={{ paddingBlock: "20px" }}>
            <AnswerLayout />
          </div>
          <TooltipComponent />
          <ManageQuestions />
        </Grid>
      </Grid>
    );
  };
  const BackgroundStructure = () => {
    return (
      <>
        <div
          className="back-structure"
          style={{
            zIndex: "0",
            top: "68px",
            bottom: "0",
            left: "0",
            right: "0",
            position: "absolute",
            width: "100%",
            height: "auto",
            opacity: `${data.background_opacity}%`,
            backgroundColor: `${data.background_color}`,
          }}
        ></div>
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${data?.question_add_an_iimage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
            zIndex: "3",
          }}
        >
          <Grid
            className="radioContainer center_part_radioContainer background-section-content"
            sx={{ marginTop: "0px !important" }}
          >
            <Grid sx={{ width: "100%", opacity: "1", zIndex: "3" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <div style={{ paddingBlock: "20px" }}>
                <AnswerLayout />
              </div>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };
  const TopStructure = () => {
    return (
      <Grid className="radioContainer" sx={{ marginTop: "0px !important" }}>
        <Grid sx={{ width: "100%" }}>
          <Typography mt={2} mb={2} variant="body1" color="inherit">
            {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
          </Typography>
          <div className="d-flex justify-center">
            <img
              src={data?.question_add_an_iimage}
              height={790 - parseInt(data.image_width)}
              width={790 - parseInt(data.image_width)}
              alt=""
              srcset=""
            />
          </div>
          <Grid>
            <Typography mt={3} variant="h4" color="inherit">
              {data.heading}
            </Typography>
            <Typography variant="h6" color="inherit">
              {data.subheading}
            </Typography>
          </Grid>
          <div style={{ paddingBlock: "20px" }}>
            <AnswerLayout />
          </div>
          <TooltipComponent />
          <ManageQuestions />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {
        {
          center: <CenterStructure />,
          left: <LeftStructure />,
          right: <RightStructure />,
          background: <BackgroundStructure />,
          top: <TopStructure />,
        }[data.image_position]
      }
    </>
  );
};

export default InfoQuestion;
