import React, { useContext, useEffect, useState } from "react";
import { Chip, CircularProgress, Grid } from "@mui/material";
import Sidenav from "../../component/Sidenav";
import {
  CButton,
  CCard,
  CCardBody,
  CCardImage,
  CCardSubtitle,
  CCardText,
  CCardTitle,
} from "@coreui/react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useNavigate, useParams } from "react-router-dom";
import Createquiz from "./Createquiz";
import Cardmenu from "./Cardmenu";
import { AuthContext } from "../../context/AuthContext";
import { FetchContext } from "../../context/FetchContext";
import { toast } from "react-hot-toast";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";
import defaultPreview from "../../public/default-quiz-preview.png";

const Allquizes = () => {
  const navigate = useNavigate();
  const { authAxios } = useContext(FetchContext);
  const { state, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const publishedQuiz = state?.allquiz?.filter(
    (state) => state.is_publish === true
  );
  useEffect(() => {
    setLoading(true);
    authAxios
      .get(`${process.env.REACT_APP_API_URL}/quiz/all_quiz`)
      .then((res) => {
        const { data } = res;
        dispatch({
          type: "ALL_QUIZ",
          payload: {
            allquiz: data.data,
          },
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        setLoading(false);
      });
    return () => {
      authAxios;
    };
  }, []);

  const preview = (e, data) => {
    if (data?.disable_intro === true) {
      navigate(`/preview/enable_intropage/${data._id}`);
    } else {
      navigate(`/preview/${data._id}`);
    }
  };

  return (
    <Sidenav>
      {loading ? (
        <Typography variant="h4" sx={{ textAlign: "center", marginTop: "20%" }}>
          <CircularProgress color="inherit" />
        </Typography>
      ) : (
        <>
          {publishedQuiz.length > 0 && (
            <div>
              <CCard
                className="allquiz_card mt-5"
                style={{
                  maxWidth: "auto",
                  backgroundColor: "transparent",
                  border: "transparent",
                }}
              >
                <div
                  style={{
                    display: " flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <CardContent>
                    <div className="d-flex text-bold">
                      <Typography gutterBottom variant="h5" component="div">
                        My Quizzes
                      </Typography>
                    </div>
                  </CardContent>
                  <CardContent>
                    <CButton
                      className={`preview text-dark`}
                      onClick={(e) => preview(e, publishedQuiz[0])}
                    >
                      Preview
                    </CButton>
                  </CardContent>
                </div>
              </CCard>
              <CCard
                className="allquiz_card mb-1 mt-1"
                style={{ maxWidth: "auto", margin: "auto" }}
              >
                <div
                  className="punlish_card"
                  style={{
                    padding: " 50px 110px 0 110px",
                    borderBottom: "1px solid #D3D4D5",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={
                      publishedQuiz[0]?.quiz_add_image
                        ? publishedQuiz[0]?.quiz_add_image
                        : defaultPreview
                    }
                    alt="Notebook cover"
                    className="publish_quiz_img"
                  />
                </div>
                <div
                  style={{
                    display: " flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <CardContent>
                    <div className="d-flex my-3 ">
                      <CCardTitle>{publishedQuiz[0]?.quiz_name}</CCardTitle>
                      <CCardSubtitle className="mx-2">
                        {publishedQuiz[0]?.is_publish === true ? (
                          <Chip className="live_chip" label="Live" />
                        ) : (
                          <Chip className="unlive_chip" label="Unpublished" />
                        )}
                      </CCardSubtitle>
                    </div>
                    <Typography variant="body2" color="text.secondary">
                      <CCardText>
                        Last Updated:{" "}
                        {new Date(publishedQuiz[0]?.updatedAt).toLocaleString(
                          "en-US"
                        )}
                      </CCardText>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Button
                      variant="contained"
                      onClick={(e) =>
                        navigate(`/quizzes/intropage/${publishedQuiz[0]?._id}`)
                      }
                      className={`m-2 dashboard_quiz_button`}
                    >
                      {" "}
                      Customize
                    </Button>
                    <span style={{ float: "right" }}>
                      <Cardmenu
                        id={publishedQuiz[0]?._id}
                        is_publish={true}
                        setLoading={setLoading}
                      />
                    </span>
                  </CardContent>
                </div>
              </CCard>
            </div>
          )}
          <Createquiz />
          <div>
            {state?.allquiz?.map((value, index) => {
              return (
                <CCard key={index} className="allquiz_card mb-4 mr-2">
                  <div className="quiz_box_card">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CCardImage
                        className="allquiz_image p-2"
                        orientation="left"
                        src={
                          value.quiz_add_image
                            ? value?.quiz_add_image
                            : defaultPreview
                        }
                      />
                    </div>
                    <CCardBody className=" my-1 card_body_content">
                      <Grid container sx={{ display: "flex" }}>
                        <Grid container item xs spacing={2}>
                          <Grid item>
                            <CCardTitle className="quiz_heading_card">
                              {value.quiz_name}
                            </CCardTitle>
                          </Grid>
                          <Grid item>
                            <CCardSubtitle>
                              {" "}
                              {value.is_publish === true ? (
                                <Chip className="live_chip" label="Live" />
                              ) : (
                                <Chip
                                  className="unlive_chip"
                                  label="Unpublished"
                                />
                              )}
                            </CCardSubtitle>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}>
                          <div>
                            <Cardmenu
                              id={value._id}
                              is_publish={value.is_publish}
                              questions={value.questions}
                              setLoading={setLoading}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <CCardText className="mt-4">
                        Last Updated:{" "}
                        {new Date(value.updatedAt).toLocaleString("en-US")}
                      </CCardText>
                      <CButton
                        className={`quiz_preview_btn preview  ${
                          value.questions.length == 0
                            ? "disabled text-secondary"
                            : "text-dark"
                        }`}
                        onClick={(e) => preview(e, value)}
                      >
                        {" "}
                        Preview
                      </CButton>
                    </CCardBody>

                    {/* <CCardBody>
                        <div className="d-flex my-3 ">
                          <CCardTitle>{value.quiz_name}</CCardTitle>
                          <CCardSubtitle className="mx-6">
                            {" "}
                            {value.is_publish === true ? (
                              <Chip className="live_chip" label="Live" />
                            ) : (
                              <Chip className="unlive_chip" label="Unpublished" />
                            )}
                          </CCardSubtitle>
                        </div>
                        <span className="card_dots mr-3">
                          <Cardmenu id={value._id} is_publish={value.is_publish} questions={value.questions} setLoading={setLoading} />
                        </span>
                        <CCardText className="mt-4">
                          Last Updated:{" "}
                          {new Date(value.updatedAt).toLocaleString("en-US")}
                        </CCardText>
                        <CButton
                          className={`mt-28 preview  ${value.questions.length == 0
                            ? "disabled text-secondary"
                            : "text-dark"
                            }`}
                          onClick={(e) => preview(e, value)}
                        >
                          {" "}
                          Preview
                        </CButton>
                      </CCardBody> */}
                  </div>
                </CCard>
              );
            })}
          </div>
        </>
      )}
    </Sidenav>
  );
};

export default Allquizes;
