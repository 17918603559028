import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Checkbox, Container, Grid, IconButton, Tooltip, tooltipClasses } from "@mui/material";
import { CFormLabel } from "@coreui/react";
import InfoIcon from "@mui/icons-material/Info";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { styled } from "@mui/material/styles";
import InputComponent from "../../../inputfiled/Input_type_text";
import InputTextAreaComponent from "../../../inputfiled/Input_type_text_area";
import InputDateComponent from "../../../inputfiled/Input_type_date";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";

const RightInputCard = ({
  imgquedata,
  question_add_an_iimage,
  allquestion,
  question_show_iimage,
}) => {

  const containerStyle = {
    position: "relative",
    width: "100%",
    // height: '100vh',
    backgroundImage: `url(${question_show_iimage ? question_show_iimage : ""})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: `${imgquedata?.background_color}`,
    opacity: `${imgquedata.background_opacity}%`,
  };
  return (
    <>
      <Grid item xs={12} sm={12} lg={8} md={12} spacing={3} className="mt-4">
        <Card
          sx={imgquedata.image_position === "background" && containerStyle}
          className="right_quiz_card"
        >
          {imgquedata.image_position === "background" && (
            <div style={overlayStyle} className="backDiv"></div>
          )}
          <CardContent>
            <Grid className="image_card_top">
              {!imgquedata.hide_progress_bar && (
                <Typography
                  variant="h6"
                  className="question_length"
                  component="div"
                >
                  {allquestion?.result?.length +
                    1 +
                    " of " +
                    (allquestion?.result?.length + 1)}
                </Typography>
              )}
              {(imgquedata.image_position === "top" ||
                imgquedata.image_position === "left") &&
                question_show_iimage.length !== 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={question_show_iimage}
                      width={
                        imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}
              <Typography sx={{ mb: 1 }} className="right_imgque_heading">
                {imgquedata.heading}
              </Typography>
              <Typography sx={{ mb: 1.5 }} className="right_imgque_subheading">
                {imgquedata.subheading}
              </Typography>
              {imgquedata.image_position === "center" &&
                question_show_iimage.length !== 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={question_show_iimage}
                      width={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}

              <div
                className="option_main_contain"
                style={{
                  margin:
                    imgquedata.box_size == "small"
                      ? "15px 10px"
                      : "15px 10px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={{ display: "flex" }}
                >
                  {/* ******************************************************************************WIDE LAYOUT START***************************** */}

                  {imgquedata.question_layout_style === "Text" && (
                    <div>
                      <InputComponent placeholder={imgquedata.placeholder} />
                      {imgquedata.add_gdpr_checkbox === true && (
                        <div className="d-flex mt-1">
                          <Checkbox
                            defaultChecked
                            className=""
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 32 },
                            }}
                          ></Checkbox>
                          <Typography
                            color="text.secondary"
                            className="mt-2 logic_jump"
                          >
                            {imgquedata?.required_agree_text == ""
                              ? "I agree to receive personalized marketing emails"
                              : imgquedata?.required_agree_text}
                          </Typography>
                        </div>
                      )}
                    </div>
                  )}
                  {imgquedata.question_layout_style === "Text Area" && (
                    <div>
                      <InputTextAreaComponent
                        placeholder={imgquedata.placeholder}
                      />
                    </div>
                  )}
                  {imgquedata.question_layout_style === "Date" && (
                    <div>
                      <InputDateComponent
                        placeholder={imgquedata.placeholder}
                      />
                    </div>
                  )}

                  {/* ******************************************************************************DEFAULT LAYOUT END***************************** */}
                </Grid>
              </div>

              {imgquedata.image_position === "right" &&
                question_show_iimage.length !== 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={question_show_iimage}
                      width={
                        imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}

              {/* 
              {imgquedata.image_position !== "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {imgquedata.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {imgquedata.subheading}
                  </Typography>
                </>
              )}

              {question_show_iimage &&
              question_add_an_iimage?.length !== 0 &&
              imgquedata.image_position !== "background" ? (
                <div
                  className={
                    imgquedata.image_position === "center" ||
                    imgquedata.image_position === "top"
                      ? "center_position"
                      : imgquedata.image_position === "right"
                      ? "right_position"
                      : "right_imgmain_image"
                  }
                >
                  <>
                    {imgquedata.image_position == "center" ||
                    imgquedata.image_position === "top" ? (
                      <img
                        className="right_rangerimage"
                        src={question_show_iimage}
                        width={`${790 - imgquedata.image_width}px`}
                        height={`${790 - imgquedata.image_width}px`}
                      />
                    ) : (
                      <img
                        className="right_rangerimage"
                        src={question_show_iimage}
                        width={`790px`}
                        height={`790px`}
                      />
                    )}
                  </>
                </div>
              ) : (
                <div className=""></div>
              )}
              {imgquedata.image_position === "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {imgquedata.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {imgquedata.subheading}
                  </Typography>
                </>
              )}
              <div
                className={
                  imgquedata.box_size === "large" &&
                  imgquedata.text_alignment === "right"
                    ? " large_right_alignment"
                    : imgquedata.box_size === "large" &&
                      imgquedata.text_alignment === "left"
                    ? " large_left_alignment"
                    : imgquedata.box_size === "large"
                    ? "large gap-2 "
                    : imgquedata.text_alignment === "left"
                    ? "left_alignment  mt-4"
                    : imgquedata.text_alignment === "right"
                    ? "right_alignment  mt-4"
                    : "d-flex mt-4 center_small_image_box"
                }
              >
                {
                  {
                    Text: (
                      <div>
                        <InputComponent placeholder={imgquedata.placeholder} />
                        {imgquedata.add_gdpr_checkbox === true && (
                          <div className="d-flex mt-1">
                            <Checkbox
                              defaultChecked
                              className=""
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                            ></Checkbox>
                            <Typography
                              color="text.secondary"
                              className="mt-2 logic_jump"
                            >
                              {imgquedata?.required_agree_text == ""
                                ? "I agree to receive personalized marketing emails"
                                : imgquedata?.required_agree_text}
                            </Typography>
                          </div>
                        )}
                      </div>
                    ),
                    "Text Area": (
                      <InputTextAreaComponent
                        placeholder={imgquedata.placeholder}
                      />
                    ),
                    Date: (
                      <InputDateComponent
                        placeholder={imgquedata.placeholder}
                      />
                    ),
                  }[imgquedata.question_layout_style]
                }
              </div>
 */}

              <div className="info_icon mt-12">
                {imgquedata.tooltip_heading ? (
                  <>
                     <Container sx={{ paddingBottom: 2 }}>
                      <Tooltip
                        arrow
                        sx={{
                          backgroundColor: "transparent",
                          ":hover": "none",
                        }}
                        title={
                          <Grid>
                            <h5 style={{ textAlign: "center" }}>
                              {imgquedata?.tooltip_heading}
                            </h5>
                            <p>{imgquedata?.tooltip_description}</p>
                          </Grid>
                        }
                      >
                        <IconButton>
                          <ReportRoundedIcon
                            sx={{ fontSize: "50px !important" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Container>
                  </>
                ) : (
                  ""
                )}
              </div>

              <Grid container className="" justifyContent="center">
                <Grid
                  className="back"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <WestIcon /> <span className="mx-2"> BACK </span>
                </Grid>
                {imgquedata.skip_que && (
                  <Grid
                    className="back"
                    xs
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span className="mx-2"> SKIP </span>
                  </Grid>
                )}

                <Grid
                  className="next"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <span className="mx-2 "> NEXT </span> <EastIcon />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default RightInputCard;
