import { CardContent, Select } from '@material-ui/core';
import { Box, Card, Grid, Typography, Link } from '@mui/material';
import LaunchIcon from "@mui/icons-material/Launch";
import React from 'react'
import Sidenav from '../Sidenav';
import { CButton } from '@coreui/react';

const Helpcenter = () => {
    return (
        <Sidenav className="container-fluid">
            <Grid container justifyContent="center">
                <Grid item lg={11}>
                    <div className="mt-12 ">
                        <span
                            style={{
                                color: "#93999c",
                                fontSize: "18px",
                                letterSpacing: "-1px",
                            }}
                        >
                            HELP CENETER
                        </span>
                        <Grid container>
                            <Grid item className="mt-4" lg={6} md={8} sm={12} xs={12}>
                                <Card className="left_second_rcard mr-2">
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <CardContent sx={{ flex: "1 0 auto" }}>
                                            <Typography
                                                component="div"
                                                variant="h6"
                                                className="mx-3"
                                                sx={{ mb: 1.5 }}
                                            >
                                                Help Center
                                            </Typography>
                                            <Typography
                                                component="div"
                                                color="text.secondary"
                                                className="logiccard_detail mx-3 mt-4"
                                            >
                                                Explore our guides for step-by-step instructions on setting up and publishing your quiz. Learn about the different ways you can use Quiz Kit to help you grow your sales and make the most out of the app!
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                    <div className="d-flex mt-4 mx-4">
                                        <CButton className={`preview text-dark`}>
                                            Learn More
                                        </CButton>
                                    </div>
                                </Card>
                            </Grid>

                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Sidenav>
    )
}

export default Helpcenter
