import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { CButton } from "@coreui/react";
import InfoIcon from "@mui/icons-material/Info";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { styled } from "@mui/material/styles";
import { Stepper } from "@progress/kendo-react-layout";
import { useParams } from "react-router-dom";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";

const EditRightRangeCard = ({
  imgquedata,
  imgansdata,
  question_add_rimage,
  allquestion,
  question_show_rimage,
}) => {
  let image_url =
    question_show_rimage.length !== 0
      ? question_show_rimage
      : imgquedata?.question_add_rimage;
  const { id } = useParams();
  const question_index = allquestion?.result?.findIndex(
    (item) => item._id === id
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  let backgroundImg;
  if (
    question_add_rimage?.length === 0 &&
    imgquedata?.question_add_rimage != 0
  ) {
    if (imgquedata.question_add_rimage) {
      backgroundImg = `url(${imgquedata.question_add_rimage})`;
    }
  } else if (question_show_rimage) {
    backgroundImg = `url(${question_show_rimage})`;
  } else {
    backgroundImg = "";
  }

  const containerStyle = {
    position: "relative",
    width: "100%",
    // height: '100vh',
    backgroundImage: backgroundImg,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: `${imgquedata?.background_color}`,
    opacity: `${imgquedata.background_opacity}%`,
  };

  return (
    <>
      <Grid item xs={12} sm={12} lg={8} md={12} spacing={3} className="mt-4">
        <Card
          sx={imgquedata.image_position === "background" && containerStyle}
          className="right_quiz_card"
        >
          {imgquedata.image_position === "background" && (
            <div style={overlayStyle} className="backDiv"></div>
          )}
          <CardContent>
            <Grid className="radio_card_top">
              {!imgquedata.hide_progress_bar && (
                <Typography
                  variant="h6"
                  className="question_length"
                  component="div"
                >
                  {question_index + 1 + " of " + allquestion?.result?.length}
                </Typography>
              )}
              {(imgquedata.image_position === "top" ||
                imgquedata.image_position === "left") &&
                (question_show_rimage.length !== 0 ||
                  imgquedata?.question_add_rimage) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={image_url}
                      width={
                        !imgquedata.image_width ||
                        imgquedata.image_width === 0 ||
                        imgquedata.image_width === "0"
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width ||
                        imgquedata.image_width === 0 ||
                        imgquedata.image_width === "0"
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}
              <Typography sx={{ mb: 1 }} className="right_imgque_heading">
                {imgquedata.heading}
              </Typography>
              <Typography sx={{ mb: 1.5 }} className="right_imgque_subheading">
                {imgquedata.subheading}
              </Typography>

              {imgquedata.image_position === "center" &&
                (question_show_rimage.length !== 0 ||
                  imgquedata?.question_add_rimage) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={image_url}
                      width={
                        !imgquedata.image_width ||
                        imgquedata.image_width === "0" ||
                        imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width ||
                        imgquedata.image_width === "0" ||
                        imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}

              <div className="option_main_contain">
                <div className={"main_option mt-8"}>
                  <Stepper
                    className=" p-4"
                    value={value}
                    onChange={(e) => handleChange(e)}
                    items={imgansdata?.map((item, index) => {
                      return {
                        label: item.answer?.title
                          ? item.answer?.title
                          : "value",
                      };
                    })}
                  />
                </div>
              </div>

              {imgquedata.image_position === "right" &&
                (question_show_rimage.length !== 0 ||
                  imgquedata?.question_add_rimage) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={image_url}
                      width={
                        !imgquedata.image_width ||
                        imgquedata.image_width === 0 ||
                        imgquedata.image_width === "0"
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width ||
                        imgquedata.image_width === 0 ||
                        imgquedata.image_width === "0"
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}

              {/* {imgquedata.image_position !== "background" && (
                <div
                  className={
                    imgquedata.image_position === "center" ||
                    imgquedata.image_position === "top"
                      ? "center_position"
                      : imgquedata.image_position === "right"
                      ? "right_position"
                      : "right_imgmain_image"
                  }
                >
                  {question_show_rimage && question_add_rimage.length !== 0 ? (
                    imgquedata.image_position == "center" ||
                    imgquedata.image_position === "top" ? (
                      <img
                        src={question_show_rimage}
                        width={`${790 - imgquedata.image_width}px`}
                        height={`${790 - imgquedata.image_width}px`}
                      />
                    ) : (
                      <img
                        src={question_show_rimage}
                        width={`790px`}
                        height={`790px`}
                      />
                    )
                  ) : question_add_rimage.length === 0 &&
                    imgquedata?.question_add_rimage != 0 ? (
                    imgquedata.image_position === "center" ||
                    imgquedata.image_position === "top" ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_rimage}`}
                        width={`${790 - imgquedata.image_width}px`}
                        height={`${790 - imgquedata.image_width}px`}
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_rimage}`}
                        width={`790px`}
                        height={`790px`}
                      />
                    )
                  ) : question_add_rimage.length === 0 &&
                    question_show_rimage.length === 0 ? (
                    ""
                  ) : (
                    ""
                  )}
                </div>
              )}
              {imgquedata.image_position === "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {imgquedata.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {imgquedata.subheading}
                  </Typography>
                </>
              )}
              <div className={"main_option mt-8"}>
                <Stepper
                  className=" p-4"
                  value={value}
                  onChange={(e) => handleChange(e)}
                  items={imgansdata?.map((item, index) => {
                    return {
                      label: item.answer?.title ? item.answer?.title : "value",
                    };
                  })}
                />
              </div> */}

              <div className="info_icon mt-12">
                {imgquedata.tooltip_heading ? (
                  <>
                    <Container sx={{ paddingBottom: 2 }}>
                      <Tooltip
                        arrow
                        sx={{
                          backgroundColor: "transparent",
                          ":hover": "none",
                        }}
                        title={
                          <Grid>
                            <h5 style={{ textAlign: "center" }}>
                              {imgquedata?.tooltip_heading}
                            </h5>
                            <p>{imgquedata?.tooltip_description}</p>
                          </Grid>
                        }
                      >
                        <IconButton>
                          <ReportRoundedIcon
                            sx={{ fontSize: "50px !important" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Container>
                  </>
                ) : (
                  ""
                )}
              </div>

              <Grid container className="" justifyContent="center">
                <Grid
                  className="back"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <WestIcon /> <span className="mx-2"> BACK </span>
                </Grid>
                {imgquedata.skip_que && (
                  <Grid
                    className="back"
                    xs
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span className="mx-2"> SKIP </span>
                  </Grid>
                )}

                <Grid
                  className="next"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <span className="mx-2 "> NEXT </span> <EastIcon />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default EditRightRangeCard;
