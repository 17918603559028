import {
  Autocomplete,
  Box,
  Chip,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const SelectAnswerLayout = ({
  setProductWeight,
  productWeight,
  data,
  setMultiValue,
  multiValue,
  setValue,
  value,
  questionNumber,
  productPoint,
  setProductPoint,
  result_json_data,
  setQuiz_logic_jump_data,
  setAnswerIds,
  answerIds,
  resultWeightIds,
  setResultWeightIds,
}) => {
  const [answerOptions, setAnswerOptions] = useState(data.answers);
  const [defaultOp, setDefaultOp] = useState([]);
  const [defaultSingleOp, setDefaultSingleOp] = useState();

  useEffect(() => {
    if (data.multiselect_answer === true) {
      setQuiz_logic_jump_data((prevData) => {
        const newData = [...prevData];
        newData[questionNumber] = data.leads_to_id;
        return newData;
      });
    }
  }, [data]);

  function localSorageData() {
    var valueArr = JSON.parse(localStorage.getItem("quizData"));
    if (valueArr && valueArr[questionNumber]?.answerId) {
      const answerIds = valueArr[questionNumber]?.answerId;
      if (data.multiselect_answer) {
        let optionsToAdd = [];
        setMultiValue(answerIds);
        answerIds.forEach((label) => {
          const option = data.answers.find((option) => option.title === label);
          const updatedOptions = data.answers.filter(
            (option) => !answerIds.includes(option.title)
          );
          if (option) {
            optionsToAdd.push({ label: option.title, id: option._id });
          }
          setAnswerOptions(updatedOptions);
        });
        setDefaultOp(optionsToAdd);
      } else {
        const singleOption = data.answers.find(
          (option) => option.title === answerIds
        );
        setValue(answerIds);
        setDefaultSingleOp({
          label: singleOption?.title,
          id: singleOption._id,
        });
      }
    }
    if (valueArr && valueArr[questionNumber]?.answerIds) {
      setAnswerIds(valueArr[questionNumber]?.answerIds);
    }
    if (valueArr && valueArr[questionNumber]?.product_weight) {
      setProductWeight(valueArr[questionNumber].product_weight);
    }
    if (valueArr && valueArr[questionNumber]?.product_point) {
      setProductPoint(valueArr[questionNumber].product_point);
    }
    if (valueArr && valueArr[questionNumber]?.resultWeightIds) {
      setResultWeightIds(valueArr[questionNumber]?.resultWeightIds);
    }
  }

  function handleSingleInputChange(event, value, reason) {
    if (reason == "clear") {
      setAnswerOptions(data.answers);
      setValue("");
      setDefaultSingleOp("");
    }
  }
  function handleInputChange(event, value, reason) {
    if (reason == "clear") {
      setAnswerOptions(data.answers);
      setMultiValue("");
    }
  }

  function handleSingleAnsValue(e, value) {
    setAnswerIds([value.id]);
    setResultWeightIds([value.item.select_result_id]);
    setQuiz_logic_jump_data((prevData) => {
      const newData = [...prevData];
      newData[questionNumber] = value?.item?.leads_to_id;
      return newData;
    });

    if (value.item.custom_redirect_url) {
      window.open(value.item.url, "_blank");
    }
    if (value.item.product_point) {
      setProductPoint(value.item.product_point);
    }
    let targetData = value.item.select_products;
    let allVariantsTemp = [];
    let maxVariantWeightsTemp = null;
    let maxWeight = -Infinity;
    let findRemove_index = -1;
    targetData.forEach((product) => {
      product.variants.forEach((variant) => {
        let newVariant = { ...variant, select_option: value.label };
        if (!productWeight.some((v) => v.select_option === value.label)) {
          allVariantsTemp.push(newVariant);
        } else {
          findRemove_index = productWeight.findIndex(
            (v) => v.select_option === value.label
          );
        }
      });
    });
    // allVariantsTemp.forEach((variant) => {
    //   const weight = parseInt(variant.variant_weight); // Convert variant_weight to integer for comparison
    //   if (weight > maxWeight) {
    //     maxWeight = weight;
    //     maxVariantWeightsTemp = variant;
    //   }
    // });
    // if (maxVariantWeightsTemp !== null) {
    //   setProductWeight([maxVariantWeightsTemp]);
    // }
    if (findRemove_index !== -1) {
      productWeight = productWeight.splice(findRemove_index, 1);
    }
    setProductWeight(allVariantsTemp);
    setValue(value.label);
    if (value.item.custom_redirect_url) {
      setDefaultSingleOp("");
    } else {
      setDefaultSingleOp(value);
    }
  }

  const handleAnsValue = (e, newvalue, reason, details) => {
    const newAnswerId = [...answerIds];
    let newAnsWeightarr = [...productWeight];
    if (details) {
      let targetData = details?.option.item?.select_products;
      let select_result_id =
        details?.option.item?.select_result_id !== null
          ? details?.option.item?.select_result_id
          : "";
      let allVariantsTemp = [];
      let findRemove_index = -1;
      const newResultWeightIds = [...resultWeightIds];
      const weightIdIndex = newResultWeightIds.indexOf(select_result_id);
      if (
        details.option.item?.custom_redirect_url &&
        reason === "selectOption" &&
        newvalue.length <= data.answer_limit
      ) {
        newvalue.pop();
        window.open(details.option.item?.url, "_blank");
      }
      if (targetData) {
        targetData.forEach((product) => {
          product.variants.forEach((variant) => {
            let newVariant = {
              ...variant,
              select_option: details.option.label,
            };
            if (
              !newAnsWeightarr.some(
                (v) => v.select_option === details.option.label
              ) &&
              newvalue.length <= data.answer_limit
            ) {
              allVariantsTemp.push(newVariant);
            } else {
              findRemove_index = newAnsWeightarr.findIndex(
                (v) => v.select_option === details.option.label
              );
            }
          });
        });
        // allVariantsTemp.forEach((variant) => {
        //   const weight = parseInt(variant.variant_weight); // Convert variant_weight to integer for comparison
        //   if (weight > maxWeight) {
        //     maxWeight = weight;
        //     maxVariantWeightsTemp = variant;
        //   }
        // });
        // if (maxVariantWeightsTemp !== null) {
        //   setProductWeight([...productWeight, maxVariantWeightsTemp]);
        // }
        if (findRemove_index !== -1) {
          newAnsWeightarr = productWeight.splice(findRemove_index, 1);
        }
      }
      if (reason == "removeOption") {
        const optionToAdd = data.answers.find(
          (option) => option._id === details.option.id
        );
        if (
          optionToAdd &&
          !answerOptions.some((option) => option._id === details.option.id)
        ) {
          setAnswerOptions([...answerOptions, optionToAdd]);
        }

        const lastValue = productPoint || 0;
        if (lastValue >= 0) {
          const newSum = lastValue + -optionToAdd.product_point;
          setProductPoint(newSum);
        }
      }

      if (reason === "selectOption" && newvalue.length <= data.answer_limit) {
        setProductWeight([...productWeight, ...allVariantsTemp]);
        if (!newAnswerId.includes(details.option.id)) {
          newAnswerId.push(details.option.id);
        }
        newResultWeightIds.push(select_result_id);
      } else {
        const index = newAnswerId.indexOf(details.option.id);
        if (index > -1) {
          newAnswerId.splice(index, 1);
          newResultWeightIds.splice(weightIdIndex, 1);
        }
        if (newvalue.length <= data.answer_limit) {
          let removeVariantArray = [];
          details.option.item?.select_products.forEach((product) => {
            // Iterate over each variant in the current product's variants array
            product.variants.forEach((variant) => {
              // Push the variant object to the variantsArray
              removeVariantArray.push(variant);
            });
          });
          const filteredProductWeight = productWeight.filter(
            (pw) => !removeVariantArray.some((rv) => rv.id === pw.id)
          );
          setProductWeight(filteredProductWeight);
        }
      }
      setAnswerIds(newAnswerId);
      const newValue_length = newvalue.length;
      if (newvalue.length >= data.answer_limit) {
        newvalue = newvalue.slice(0, data.answer_limit);
      }
      if (
        reason == "selectOption" &&
        newValue_length <= data.answer_limit &&
        !details.option.item.custom_redirect_url
      ) {
        const updatedOptions = answerOptions.filter(
          (option) => option._id !== details.option.id
        );
        setAnswerOptions(updatedOptions);
        if (details.option.item?.product_point) {
          const productPointValue = details.option.item?.product_point;
          const lastValue = productPoint || 0;
          const newSum = lastValue + +productPointValue;
          setProductPoint(newSum);
        }
      }
      setResultWeightIds(newResultWeightIds);
    }
    const selectedvalue = newvalue.map((item) => {
      return item.label;
    });

    setMultiValue(selectedvalue);
    setDefaultOp(newvalue);
  };
  useEffect(() => {
    localSorageData();
  }, [questionNumber]);
  return (
    <>
      <Container>
        {data.multiselect_answer == true ? (
          <div className="select_border" style={{ width: "100%" }}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={answerOptions.map((item, index) => {
                return { label: item?.title, id: item._id, item, index };
              })}
              onChange={(e, newvalue, reason, details) =>
                handleAnsValue(e, newvalue, reason, details)
              }
              value={defaultOp}
              // filterSelectedOptions
              onInputChange={(event, value, reason) =>
                handleInputChange(event, value, reason)
              }
              renderInput={(params) => (
                <TextField
                  style={{ color: "black" }}
                  {...params}
                  className="select_border_width"
                  placeholder={
                    data?.placeholder ? data?.placeholder : "Choose an option"
                  }
                />
              )}
            />
          </div>
        ) : (
          <div className="select_border" style={{ width: "100%" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={data.answers.map((item, index) => {
                return { label: item?.title, id: item._id, item };
              })}
              onChange={(e, value) => handleSingleAnsValue(e, value)}
              onInputChange={(event, value, reason) =>
                handleSingleInputChange(event, value, reason)
              }
              value={defaultSingleOp ?? ""}
              renderInput={(params) => (
                <TextField
                  style={{ color: "black" }}
                  {...params}
                  className="select_border_width"
                  placeholder={
                    data?.placeholder ? data?.placeholder : "Choose an option"
                  }
                />
              )}
            />
          </div>
        )}
      </Container>
    </>
  );
};

export default SelectAnswerLayout;
