import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@mui/material/Typography";
import IconButton from "@material-ui/core/IconButton";

import {
  Backdrop,
  CircularProgress,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FetchContext } from "../../context/FetchContext";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
const ITEM_HEIGHT = 60;

export default function CardIntro({ quiz, id, quizquestion, setquizquestion }) {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const { authAxios } = useContext(FetchContext);
  const [loading, setloading] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [qustionId, setQuestionId] = useState();
  const handleDeletePopupClose = () => {
    setDeleteModalOpen(false);
  };
  function handleDeletePopupOpen(e, _id) {
    setDeleteModalOpen(true);
    setQuestionId(_id);
  }
  function getquestiondata() {
    authAxios
      .post(`${process.env.REACT_APP_API_URL}/question/show_question/${id}`)
      .then((res) => {
        setquizquestion(res.data);
        setloading(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          setloading(false);
        }
      });
  }
  const handledeletequestion = (_id) => {
    setloading(true);
    setDeleteModalOpen(false);
    setloading(true);
    authAxios
      .delete(
        `${process.env.REACT_APP_API_URL}/question/delete_question/${qustionId}`
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          getquestiondata();
          setloading(false);
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          setloading(false);
        }
      });
  };

  React.useEffect(() => {}, [id, quiz]);
  const add_question = (e) => {
    navigate(`/questiontype/${id}`);
  };
  const editform = () => {
    navigate(`/edit/quizzes/Intro_quizpage/${id}`);
  };
  const editformquestion = (item) => {
    switch (item.question_types) {
      case "Image with text":
        return navigate(`/edit/quiz/image_question/${item._id}`);
      case "Radio buttons":
        return navigate(`/edit/quiz/Radio_question/${item._id}`);
      case "Text box":
        return navigate(`/edit/quiz/text_box_question/${item._id}`);
      case "Input":
        return navigate(`/edit/quiz/input_question/${item._id}`);
      case "Range slider":
        return navigate(`/edit/quiz/range_question/${item._id}`);
      case "select":
        return navigate(`/edit/quiz/select_question/${item._id}`);
      case "info":
        return navigate(`/edit/quiz/info_question/${item._id}`);
      case "File upload":
        return navigate(`/edit/quiz/file_upload_question/${item._id}`);
    }
  };

  return (
    <>
      <div>
        <Modal
          open={deleteModalOpen}
          onClose={handleDeletePopupClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
              <div className="main_modal_div">
                <div
                  id="modal-modal-title"
                  className="product_modal_header py-0 px-2"
                >
                  <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                    Delete
                  </Typography>
                  <Typography>
                    <IconButton
                      sx={{ padding: "0px" }}
                      onClick={handleDeletePopupClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Typography>
                </div>
                <Box className="">
                  <div className="product_modal_body">
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, padding: " 5px 10px" }}
                    >
                      Are you sure you want to delete?
                    </Typography>
                    <div
                      style={{
                        padding: "5px 0px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "start",
                      }}
                    ></div>
                  </div>
                </Box>
                {quiz.is_publish == true &&
                  quizquestion.result.length === 1 && (
                    <div className="text-danger me-3 mb-2 text-end">
                      Note: If you delete this question, your published quiz
                      will become unpublished.
                    </div>
                  )}{" "}
                <div
                  className="product_modal_footer"
                  style={{
                    padding: "10px 10px",

                    gap: "15px",
                  }}
                >
                  <Button
                    onClick={handleDeletePopupClose}
                    className="delete_modal_no"
                    variant="contained"
                  >
                    No
                  </Button>
                  <Button
                    onClick={(e) => handledeletequestion(e)}
                    className="product_modal_save"
                    variant="contained"
                  >
                    Yes
                  </Button>
                </div>
              </div>
        </Modal>
      </div>
      {loading ? (
        <div sx={{ display: "flex" }}>
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit"  />
          </Backdrop>
        </div>
      ) : (
        <Grid container spacing={2} sx={{ display: "flex" }}>
          <Grid
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            item
            sx={{ display: "flex" }}
          >
            <Card className="card_intro ">
              <CardContent className="m-2 p-3 pt-4">
                <Typography variant="h6" gutterBottom>
                  Intro page{" "}
                  <span className="float-right">
                    <MoreVertIcon onClick={editform} />
                  </span>
                </Typography>

                <Typography
                  className="intro_first_card"
                  variant="body1"
                  component="div"
                >
                  {quizquestion?.quiz?.heading}
                </Typography>
                <Typography variant="body2" className="" color="text.secondary">
                  {quizquestion?.quiz?.button_text}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {quizquestion?.result?.map((item, index) => (
            <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className=""
              sx={{ display: "flex" }}
            >
              <Card key={index} className="card_intro ">
                <CardContent className="m-2 p-3 pt-4">
                  <Typography variant="h6">
                    Question {index + 1}
                    <span style={{ textAlign: "end" }} className=" float-right">
                      <EditIcon
                        className="icons mx-1"
                        onClick={() => editformquestion(item)}
                      />
                      <DeleteIcon
                        className="icons"
                        onClick={(e) => handleDeletePopupOpen(e, item._id)}
                      />
                    </span>
                  </Typography>
                  <Typography
                    sx={{ mb: 2.5 }}
                    color="text.secondary"
                    component="div"
                    variant="subtitle2"
                  >
                    {item.question_types}
                  </Typography>
                  <Typography variant="body1" className="question_card_heading">
                    {item.heading}
                  </Typography>
                  <div className="mb-2 mt-2">
                    <Typography variant="body2" color="text.secondary">
                      {
                        item.answers?.map(
                          (option, index) =>
                            option.leads_question_id === item._id
                        ).length
                      }{" "}
                      options
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}

          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <Card
              onClick={(e) => add_question(e)}
              className="card_intro plus_card"
            >
              <CardContent>
                <Typography
                  className="d-flex justify-center"
                  sx={{ mt: 0.5 }}
                  variant="h1"
                >
                  <AddIcon className="question_plus" />
                </Typography>
                <Typography
                  className="d-flex justify-center add_question"
                  sx={{ mt: 1, height: "50px" }}
                  component="div"
                >
                  Add question
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
}
