import { Button, Chip, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Buttton from "../Button/Buttton";
import { FetchContext } from "../../context/FetchContext";
import { AuthContext } from "../../context/AuthContext";

const CreateQuestionTitle = ({
  link,
  quiz_name,
  is_status,
  loading,
  isSaveDisable,
  questionsave,
  preview,
  quiz_id,
  questions,
  is_publish,
  viewTitle
}) => {
  const { authAxios } = useContext(FetchContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const { state, dispatch } = useContext(AuthContext);

  useEffect(() => {
    if (questions?.result?.length !== 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [quiz_id, questions]);
  useEffect(() => {
    if (viewTitle === true) {
      console.log("viewTitle---->", viewTitle);

      authAxios
        .get(`${process.env.REACT_APP_API_URL}/quiz/all_quiz`)
        .then((res) => {
          const { data } = res;
          dispatch({
            type: "ALL_QUIZ",
            payload: {
              allquiz: data.data,
            },
          });
        })
        .catch((err) => {
          if (err) {
            console.log(err);
          }
        });
      return () => {
        authAxios;
      };
    }
  }, [is_publish, quiz_name]);
  return (
    <Grid container justifyContent="space-between">
      <Grid className="" xs={12} sm={6} md={6}>
        <div className="d-flex">
          <Link to={link}>
            <KeyboardBackspaceRoundedIcon className="intro_backbutton" />{" "}
          </Link>
          <div className="mx-4 intro_quizname mt-2">{quiz_name}</div>
          <div className="mt-1">
            {is_publish ? (
              <Chip className="live_chip" label="Live" />
            ) : (
              <Chip className="unlive_chip" label="Unpublished" />
            )}
          </div>
        </div>
      </Grid>
      <Grid className="save_button" xs={12} sm={6} md={6}>
        <div className="d-flex">
          <Button
            className={`mr-10 preview_btn ${isDisabled !== true ? "disabled text-secondary" : "text-dark"
              }`}
            onClick={(e) => isDisabled == true && preview()}
          >
            <VisibilityIcon />{" "}
            <span className="preview_save mx-1 me-3"> Preview </span>
          </Button>
          {questionsave !== undefined && (
            <Buttton
              handleClick={questionsave}
              className="savebutton"
              title={"Save"}
              disabled={isSaveDisable}
              loading={loading}
            />
          )}
        </div>
      </Grid>
    </Grid>

    // {/* <div className="d-flex">
    // <Grid className="intro_back " xs={6}>
    //   <div className="mx-4 d-flex">
    //     <Link to={link}>
    //       <KeyboardBackspaceRoundedIcon className="intro_backbutton" />{" "}
    //     </Link>
    //     <div className="mx-4 intro_quizname mt-2">{quiz_name}</div>
    //     <div className="mt-1">
    //       {is_publish ? (
    //         <Chip className="live_chip" label="Live" />
    //       ) : (
    //         <Chip className="unlive_chip" label="Unpublished" />
    //       )}
    //     </div>
    //   </div>
    // </Grid>
    // <Grid className="save_button mr-6 " xs={6}>
    //   <Grid
    //     className={`mr-10 mt-2 ${
    //       isDisabled !== true ? "disabled text-secondary" : "text-dark"
    //     }`}
    //     onClick={(e) => isDisabled == true && preview()}
    //   >
    //     <VisibilityIcon />{" "}
    //     <span className="preview_save mx-1"> preview </span>
    //   </Grid>
    //   {questionsave !== undefined && (
    //     <Buttton
    //       handleClick={questionsave}
    //       className="savebutton"
    //       title={"Save"}
    //       disabled={isSaveDisable}
    //       loading={loading}
    //     />
    //   )}
    // </Grid>
    // </div> */}
  );
};

export default CreateQuestionTitle;
